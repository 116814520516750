// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_organization_job_position.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum com.diagnocat.model.OrganizationJobPosition
 */
export const OrganizationJobPosition = proto3.makeEnum(
  "com.diagnocat.model.OrganizationJobPosition",
  [
    {no: 0, name: "OrganizationJobPositionInvalidValue"},
    {no: 1, name: "OrganizationJobPositionChiefDentalOfficer"},
    {no: 2, name: "OrganizationJobPositionTreatmentCoordinator"},
    {no: 3, name: "OrganizationJobPositionDentalAssistant"},
    {no: 4, name: "OrganizationJobPositionDentalTechnician"},
    {no: 5, name: "OrganizationJobPositionGeneralDentist"},
    {no: 6, name: "OrganizationJobPositionPeriodontist"},
    {no: 7, name: "OrganizationJobPositionProsthodontist"},
    {no: 8, name: "OrganizationJobPositionOrthodontist"},
    {no: 9, name: "OrganizationJobPositionPediatricDentist"},
    {no: 10, name: "OrganizationJobPositionOralAndMaxillofacialSurgeon"},
    {no: 11, name: "OrganizationJobPositionOralAndMaxillofacialRadiologist"},
    {no: 12, name: "OrganizationJobPositionAestheticDentist"},
    {no: 13, name: "OrganizationJobPositionDentalHygienist"},
    {no: 14, name: "OrganizationJobPositionOther"},
    {no: 15, name: "OrganizationJobPositionOfficeManager"},
    {no: 16, name: "OrganizationJobPositionEndodontist"},
  ],
);

