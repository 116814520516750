import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import { ToastContainer } from 'react-toastify';
import { ConfigProvider } from 'antd';

import { defaultValue, ErrorContext } from '@/shared/error/ErrorContext';
import ErrorBoundary from '@/shared/error/ErrorBoundary';

import { Routes } from './model/routes';
import { store } from './model/store';
import { IntlProvider } from './providers/IntlProvider';

export const App = () => (
  <ErrorContext.Provider value={defaultValue}>
    <ErrorBoundary>
      <ConfigProvider theme={{ token: { colorPrimary: "#7564ed" } }}>
        <Provider store={store}>
          <IntlProvider>
            <RadixTooltip.Provider>
              <Router>
                <Routes />
                <ToastContainer icon={false} hideProgressBar />
              </Router>
            </RadixTooltip.Provider>
          </IntlProvider>
        </Provider>
      </ConfigProvider>
    </ErrorBoundary>
  </ErrorContext.Provider>
);
