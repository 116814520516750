// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header-module__header--DOhDuBuL{display:flex;align-items:center;gap:12px;background-color:#fff !important}\n\n.header-module__logoLink--lJ9PofFW{margin-right:auto}\n\n[dir=\"rtl\"] .header-module__logoLink--lJ9PofFW{margin-right:0;margin-left:auto}.header-module__logoutButton--2-jR2QO-{padding-left:0 !important;padding-right:0 !important}.header-module__headerInner--pMzVeN6k{display:flex;margin:0 auto;flex-grow:1;align-items:center;justify-content:space-between;max-width:var(--max-content-width)}.header-module__headerActions--KXce1aBz{display:flex;gap:16px;align-items:center}", "",{"version":3,"sources":["webpack://./src/widgets/header/ui/header.module.scss"],"names":[],"mappings":"AAAA,iCACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,gCAAA;;AAGF,mCACE,iBAAA;;AADF,+CACE,cAAA,CAAA,gBAAA,CAGF,uCACE,yBAAA,CACA,0BAAA,CAGF,sCACE,YAAA,CACA,aAAA,CACA,WAAA,CACA,kBAAA,CACA,6BAAA,CACA,kCAAA,CAGF,wCACE,YAAA,CACA,QAAA,CACA,kBAAA","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  background-color: #fff !important;\n}\n\n.logoLink {\n  margin-right: auto;\n}\n\n.logoutButton {\n  padding-left: 0!important;\n  padding-right: 0!important;\n}\n\n.headerInner {\n  display: flex;\n  margin: 0 auto;\n  flex-grow: 1;\n  align-items: center;\n  justify-content: space-between;\n  max-width: var(--max-content-width);\n}\n\n.headerActions {\n  display: flex;\n  gap: 16px;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "header-module__header--DOhDuBuL",
	"logoLink": "header-module__logoLink--lJ9PofFW",
	"logoutButton": "header-module__logoutButton--2-jR2QO-",
	"headerInner": "header-module__headerInner--pMzVeN6k",
	"headerActions": "header-module__headerActions--KXce1aBz"
};
export default ___CSS_LOADER_EXPORT___;
