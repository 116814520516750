// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_user.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { Created, Gender, PersonalData, Revision } from "./dto_base_pb.js";
import { UserPermissions } from "./dto_user_permissions_pb.js";

/**
 * @generated from message com.diagnocat.model.User
 */
export const User = proto3.makeMessageType(
  "com.diagnocat.model.User",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "PersonalData", kind: "message", T: PersonalData },
    { no: 3, name: "Age", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "Gender", kind: "enum", T: proto3.getEnumType(Gender) },
    { no: 5, name: "AvatarAssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "SignatureAssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "Settings", kind: "message", T: UserSettings },
    { no: 12, name: "IsAdmin", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10020, name: "YourPermissions", kind: "message", T: UserPermissions },
    { no: 10030, name: "Created", kind: "message", T: Created },
    { no: 10040, name: "Revision", kind: "message", T: Revision },
  ],
);

/**
 * @generated from message com.diagnocat.model.UserSettings
 */
export const UserSettings = proto3.makeMessageType(
  "com.diagnocat.model.UserSettings",
  () => [
    { no: 1, name: "Language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "Country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

