import { createSlice, DeepPartial, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStateType, SliceName } from '@/shared/config';
import { ProductModel, ProductState } from 'protocol/api/billing_new/dto_product_new_pb';
import { Account } from 'protocol/api/billing_new/dto_account_new_pb';
import { Order } from 'protocol/api/billing_new/dto_order_new_pb';
import { CreateOrderReq } from 'protocol/api/billing_new/svc_billing_new_pb';

export type BillingStateLoadersKeys = 'accountInfo' | 'billingZones' | 'orders' | 'newOrder';

type BillingStateLoaders = Record<BillingStateLoadersKeys, LoadingStateType>;

export type EffectToAddType = DeepPartial<CreateOrderReq['Effect']>;

const billingInitialState = {
  accountInfo: {} as Account,
  billingZones: [] as string[],
  orders: [] as Order[],
  products: [] as ProductModel[],
  inventory: [] as ProductState[],
  effectToAdd: {} as EffectToAddType,
  loaders: {
    accountInfo: 'idle',
    orders: 'idle',
    newOrder: 'idle',
  } as BillingStateLoaders,
};

const billingSlice = createSlice({
  name: SliceName.billing,
  initialState: billingInitialState,
  reducers: {
    setAccountInfo: (state, { payload }: PayloadAction<Account>) => {
      state.accountInfo = payload;
    },
    setOrders: (state, { payload }: PayloadAction<Order[]>) => {
      state.orders = payload;
    },
    setLoading: (state, { payload }: PayloadAction<{ loaderKey: BillingStateLoadersKeys, loaderState: LoadingStateType }>) => {
      state.loaders[payload.loaderKey] = payload.loaderState;
    },
    setAvailableProducts: (state, { payload }: PayloadAction<ProductModel[]>) => {
      state.products = payload;
    },
    updateInventory: (state, { payload }: PayloadAction<ProductState>) => {
      const inventoryForUpdateIndex = state.inventory.findIndex(
        (inventoryItem) => inventoryItem.ID === payload.ID,
      );

      if (inventoryForUpdateIndex < 0) {
        state.inventory.push(payload);
      } else {
        state.inventory[inventoryForUpdateIndex] = payload;
      }
    },
    updateOrder: (state, { payload }: PayloadAction<Order>) => {
      const orderForUpdateIndex = state.orders.findIndex(
        (order) => order.ID === payload.ID,
      );

      if (orderForUpdateIndex < 0) {
        state.orders.push(payload);
      } else {
        state.orders[orderForUpdateIndex] = payload;
      }
    },
    setInventory: (state, { payload }: PayloadAction<ProductState[]>) => {
      state.inventory = payload;
    },
    setEffectToAdd: (state, { payload }: PayloadAction<EffectToAddType>) => {
      state.effectToAdd = payload;
    },
    reset: () => billingInitialState,
  },
});

export const { actions } = billingSlice;

export default billingSlice.reducer;
