import { Button, Layout, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import { useLocation } from 'react-router';

import logo from '@/shared/assets/logo.svg';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { PATHS } from '@/shared/config';
import { authModel } from '@/entities/auth';
import { userModel } from '@/entities/user';
import { LanguageSwitcher } from '@/features/languageSwitcher';

import styles from './header.module.scss';

export const Header = () => {
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const adminUser = useAppSelector(userModel.selectors.selectAdminUser);

  const adminUserEmail = adminUser?.PersonalData?.Emails[0];

  const logout = async () => {
    setLoading(true);

    try {
      await dispatch(authModel.thunks.logout({})).unwrap();

      navigate(PATHS.signIn, { state: { from: location } });
    } catch (error) {
      const parsedMessage = JSON.parse(error?.message);

      message.error({ content: parsedMessage?.text ?? parsedMessage?.reason });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout.Header className={styles.header}>
      <Link to="/" className={styles.logoLink}> 
        <img src={logo} height={32} alt="Diagnocat" />
      </Link>

      {adminUserEmail && <span>{adminUserEmail}</span>}

      <LanguageSwitcher />

      <Button type="link" className={styles.logoutButton} disabled={loading} onClick={logout}> 
        <FormattedMessage id="header.logoutButton" defaultMessage="Logout" />
      </Button>
    </Layout.Header>
  );
};
