import { useEffect } from 'react';
import { Code, ConnectError } from '@bufbuild/connect';

import api from '@/shared/api/api';
import { useAppDispatch } from '@/shared/hooks';
import { Order } from 'protocol/api/billing_new/dto_order_new_pb';
import { ProductState } from 'protocol/api/billing_new/dto_product_new_pb';

import * as billingModel from '../model';

let abortController: AbortController;

enum AccountInfoAccumulatorKeys {
  Orders = 'Orders',
  Inventory = 'Inventory',
}

type AccountInfoStreamAccumulators = {
  [AccountInfoAccumulatorKeys.Orders]: Order[];
  [AccountInfoAccumulatorKeys.Inventory]: ProductState[];
};

const accountInfoStreamAccumulators: AccountInfoStreamAccumulators = {
  [AccountInfoAccumulatorKeys.Orders]: [],
  [AccountInfoAccumulatorKeys.Inventory]: [],
};

export const useAccountInfoStream = (organizationID: string) => {
  const dispatch = useAppDispatch();

  const openAccountInfoStream = async () => {
    abortController = new AbortController();

    const accountInfoStream = api.billing.accountInfoStream(
      {
        OrganizationID: organizationID,
      },
      { signal: abortController.signal },
    );

    try {
      for await (const { Update } of accountInfoStream) {
        switch (Update.case) {
          case 'AccountInfo': {
            dispatch(billingModel.actions.setAccountInfo(Update.value));

            dispatch(
              billingModel.actions.setLoading({
                loaderKey: 'accountInfo',
                loaderState: 'succeeded',
              }),
            );
            break;
          }
          case 'Order': {
            if (Update.value?.Kind?.case === 'Billed') {
              dispatch(billingModel.actions.updateOrder(Update.value));
            }
            break;
          }

          case 'HistoricalOrder': {
            if (Update.value?.Kind?.case === 'Billed') {
              accountInfoStreamAccumulators[
                AccountInfoAccumulatorKeys.Orders
              ].push(Update.value);
            }
            break;
          }

          case 'Inventory': {
            dispatch(billingModel.actions.updateInventory(Update.value));
            break;
          }

          case 'HistoricalInventory': {
            accountInfoStreamAccumulators[
              AccountInfoAccumulatorKeys.Inventory
            ].push(Update.value);
            break;
          }

          case 'EndOfHistoricalOrder': {
            dispatch(
              billingModel.actions.setOrders(
                accountInfoStreamAccumulators[
                  AccountInfoAccumulatorKeys.Orders
                ],
              ),
            );

            accountInfoStreamAccumulators[AccountInfoAccumulatorKeys.Orders] =
              [];

            dispatch(
              billingModel.actions.setLoading({
                loaderKey: 'orders',
                loaderState: 'succeeded',
              }),
            );
            break;
          }
          case 'EndOfHistoricalInventory': {
            dispatch(
              billingModel.actions.setInventory(
                accountInfoStreamAccumulators[
                  AccountInfoAccumulatorKeys.Inventory
                ],
              ),
            );

            accountInfoStreamAccumulators[
              AccountInfoAccumulatorKeys.Inventory
            ] = [];

            dispatch(
              billingModel.actions.setLoading({
                loaderKey: 'orders',
                loaderState: 'succeeded',
              }),
            );
            break;
          }
          default: {
            // do nothing
          }
        }
      }
    } catch (error) {
      if (error instanceof ConnectError && error.code !== Code.Canceled) {
        console.error(
          'AccountInfoStream:: error: ',
          error.message,
          error.code,
          error,
        );
      }
    }
  };

  useEffect(() => {
    if (organizationID) {
      openAccountInfoStream();
    }

    return () => {
      abortController?.abort();

      dispatch(billingModel.actions.reset());
    };
  }, [dispatch, organizationID]);
};
