// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer-module__container--HxvWJI2i{text-align:center}", "",{"version":3,"sources":["webpack://./src/widgets/footer/ui/footer.module.scss"],"names":[],"mappings":"AAAA,oCACE,iBAAA","sourcesContent":[".container {\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "footer-module__container--HxvWJI2i"
};
export default ___CSS_LOADER_EXPORT___;
