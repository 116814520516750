import { useIntl } from 'react-intl';
import { Modal, Space, Typography } from 'antd';

import { useAppDispatch } from '@/shared/hooks';

import * as billingModel from '../model';

const { Text } = Typography;

export const useRemoveSubscriptionModal = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  return ({
    subscriptionID,
    accountID,
  }: {
    subscriptionID: string;
    accountID: string;
  }) => {
    Modal.confirm({
      title: formatMessage({
        id: 'removeSubscriptionModal.removeSubscriptionModalTitle',
        defaultMessage: 'ATTENTION!',
      }),
      cancelText: formatMessage({
        id: 'removeSubscriptionModal.removeSubscriptionModalCancelText',
        defaultMessage: "No, I didn't mean it!",
      }),
      okText: formatMessage({
        id: 'removeSubscriptionModal.removeSubscriptionModalOkText',
        defaultMessage: 'Yes, remove subscription',
      }),
      content: (
        <Space direction="vertical">
          <Text>
            {formatMessage({
              id: 'removeSubscriptionModal.removeSubscriptionModalContent',
              defaultMessage:
                'You are about to remove user subscription. It will disrupt user ability to use the application because they will lose their subscription allowance. Please make sure they are aware of their subscription removal.',
            })}
          </Text>
          <Text strong>
            {formatMessage({
              id: 'removeSubscriptionModal.removeSubscriptionModalContent2',
              defaultMessage:
                'Are you sure you want to remove the subscription?',
            })}
          </Text>
        </Space>
      ),
      okButtonProps: {
        danger: true,
      },
      centered: true,
      width: 600,
      onOk() {
        return dispatch(
          billingModel.thunks.createOrder({
            AccountID: accountID, // TODO: change to organization id
            Effect: {
              case: 'ProductStateRemove',
              value: {
                RemoveProductStateID: subscriptionID,
              }
            }
          }),
        );
      },
    });
  };
};
