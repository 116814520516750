export const PATHS = {
  main: '/',
  signUp: '/sign-up',
  signIn: '/sign-in',
  user: '/user/:userID',
  organization: '/organization/:organizationID',
  addSubscription: '/organization/:organizationID/add-subscription',
  addAddon: '/organization/:organizationID/add-addon',
  addPackage: '/organization/:organizationID/add-package',
};
