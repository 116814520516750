import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

import {
  APP_VERSION,
  ENVIRONMENT,
  IS_ERROR_TRACKING_ENABLED,
  SENTRY_DSN,
  SENTRY_TRACES_SAMPLE_RATE,
} from '@/shared/config';

interface ErrorTracker {
  notify(err: Error, opts?: unknown): void;
}

const IGNORED_ERRORS_LIST = [
  'Network Error',
  'Not authenticated',
  'Access denied to',
  'ResizeObserver loop completed with undelivered notifications.',
];

class ErrorTrackerImpl implements ErrorTracker {
  constructor() {
    if (IS_ERROR_TRACKING_ENABLED) {
      Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [
          new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
              useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes,
            ),
          }),
        ],
        ignoreErrors: IGNORED_ERRORS_LIST,
        tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
        environment: ENVIRONMENT,
        release: APP_VERSION,
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  notify(err: Error): void {
    if (IS_ERROR_TRACKING_ENABLED) {
      Sentry.captureException(err);
    }
  }
}

export const errorTracker: ErrorTracker = new ErrorTrackerImpl();
