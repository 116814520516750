import { RootState } from '@/app/model/store';

import { usersAdapter } from 'src/entities/users/model/usersSlice';

const usersSelectors = usersAdapter.getSelectors(
  (state: RootState) => state.users,
);

export const selectById = (userID: string) => (state: RootState) =>
  usersSelectors.selectById(state, userID);

export const selectLoading = (state: RootState) => state.users.loading;

export const { selectIds, selectAll, selectTotal, selectEntities } =
  usersSelectors;
