// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".User-module__space--N8zoJBs-{display:flex}", "",{"version":3,"sources":["webpack://./src/pages/User/User.module.scss"],"names":[],"mappings":"AAAA,8BACE,YAAA","sourcesContent":[".space {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"space": "User-module__space--N8zoJBs-"
};
export default ___CSS_LOADER_EXPORT___;
