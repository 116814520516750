import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useState } from 'react';
import { Col, Form, Input, Modal, notification, Row, Select } from 'antd';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { billingModel } from '@/entities/billing';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { useCountries } from '@/features/countries';
import { PATHS } from '@/shared/config';

type AdditionalDataFormProps = {
  isOpen: boolean;
  onCloseHandler: () => void;
};

type AdditionalDataFormFields = {
  zip: string;
  country: string;
  region: string;
  city: string;
  address: string;
  phone: string;
  companyName: string;
  taxID: string;
};

export const AdditionalDataForm: FC<AdditionalDataFormProps> = (props) => {
  const { isOpen, onCloseHandler } = props;

  const [loading, setLoading] = useState<boolean>(false);

  const { organizationID } = useParams<{ organizationID: string }>();
  const navigate = useNavigate();

  const { formatMessage } = useIntl();

  const {
    ID: accountID,
    BillingInformation,
    Country,
  } = useAppSelector(billingModel.selectors.selectAccountInfo);
  const effectToAdd = useAppSelector(
    billingModel.selectors.selectSubscriptionToAdd,
  );

  const dispatch = useAppDispatch();

  const [form] = Form.useForm<AdditionalDataFormFields>();
  const countryOptions = useCountries();

  const handleSubmit = () =>
    form
      .validateFields()
      .then(async (values) => {
        const {
          zip,
          country,
          region,
          city,
          address,
          phone,
          companyName,
          taxID,
        } = values;

        const billingAddress = {
          Country: country,
          Region: region,
          City: city,
          ZIP: zip,
          Address: address,
        };

        const billingInformationRequest = {
          AccountID: accountID,
          BillingInformation: {
            Address: billingAddress,
            Phone: phone,
            CompanyName: companyName,
            TaxID: taxID,
          },
        };

        try {
          setLoading(true);

          await dispatch(
            billingModel.thunks.setAccountBillingInformation(
              billingInformationRequest,
            ),
          ).unwrap();

          dispatch(
            billingModel.actions.setLoading({
              loaderKey: 'newOrder',
              loaderState: 'pending',
            }),
          );

          await dispatch(
            billingModel.thunks.createOrder({
              AccountID: accountID,
              Effect: effectToAdd,
            }),
          ).unwrap();

          navigate(generatePath(PATHS.organization, { organizationID }));

          onCloseHandler();
        } catch (error) {
          notification.error({
            message: error,
          });
        } finally {
          setLoading(false);
        }
      })
      .catch(({ errorFields }) => console.error(errorFields));

  return (
    <Modal
      open={isOpen}
      centered
      closable={false}
      okText={<FormattedMessage id="global.confirm" defaultMessage="Confirm" />}
      cancelText={
        <FormattedMessage id="global.cancel" defaultMessage="Cancel" />
      }
      onCancel={onCloseHandler}
      onOk={handleSubmit}
      confirmLoading={loading}
    >
      <Form
        name="additionalInfoForm"
        form={form}
        layout="vertical"
        initialValues={{
          zip: BillingInformation?.Address?.ZIP,
          country: Country,
          region: BillingInformation?.Address?.Region,
          city: BillingInformation?.Address?.City,
          address: BillingInformation?.Address?.Address,
          phone: BillingInformation?.Phone,
          companyName: BillingInformation?.CompanyName,
          taxID: BillingInformation?.TaxID,
        }}
      >
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="country"
              label={
                <FormattedMessage
                  id="migrateAccount.modal.formItem.country"
                  defaultMessage="Country"
                />
              }
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: 'forms.required',
                    defaultMessage: 'Field is required',
                  }),
                },
              ]}
            >
              <Select disabled={Boolean(Country)} options={countryOptions} />
            </Form.Item>
            <Form.Item
              name="city"
              label={
                <FormattedMessage
                  id="additionalForm.formItem.city"
                  defaultMessage="City"
                />
              }
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: 'forms.required',
                    defaultMessage: 'Field is required',
                  }),
                },
              ]}
            >
              <Input
                placeholder={formatMessage({
                  id: 'additionalForm.formItem.city',
                  defaultMessage: 'City',
                })}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="region"
              label={
                <FormattedMessage
                  id="migrateAccount.modal.formItem.region"
                  defaultMessage="State/Region"
                />
              }
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: 'forms.required',
                    defaultMessage: 'Field is required',
                  }),
                },
              ]}
            >
              <Input
                placeholder={formatMessage({
                  id: 'additionalForm.formItem.region',
                  defaultMessage: 'State/Region',
                })}
              />
            </Form.Item>
            <Form.Item
              name="zip"
              label={
                <FormattedMessage
                  id="additionalForm.formItem.zipCode"
                  defaultMessage="ZIP code"
                />
              }
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: 'forms.required',
                    defaultMessage: 'Field is required',
                  }),
                },
              ]}
            >
              <Input
                placeholder={formatMessage({
                  id: 'additionalForm.formItem.zipCode',
                  defaultMessage: 'ZIP code',
                })}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="address"
          label={
            <FormattedMessage
              id="additionalForm.formItem.billingAddress"
              defaultMessage="Billing address"
            />
          }
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'forms.required',
                defaultMessage: 'Field is required',
              }),
            },
          ]}
        >
          <Input
            placeholder={formatMessage({
              id: 'additionalForm.formItem.billingAddress',
              defaultMessage: 'Billing address',
            })}
          />
        </Form.Item>

        <Form.Item
          name="phone"
          label={
            <FormattedMessage
              id="additionalForm.formItem.phone"
              defaultMessage="Phone"
            />
          }
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'forms.required',
                defaultMessage: 'Field is required',
              }),
            },
          ]}
        >
          <Input
            placeholder={formatMessage({
              id: 'additionalForm.formItem.phone',
              defaultMessage: 'Phone',
            })}
          />
        </Form.Item>
        <Form.Item
          name="companyName"
          label={
            <FormattedMessage
              id="additionalForm.formItem.companyName"
              defaultMessage="Company name"
            />
          }
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input
            placeholder={formatMessage({
              id: 'additionalForm.formItem.companyName',
              defaultMessage: 'Company name',
            })}
          />
        </Form.Item>

        <Form.Item
          name="taxID"
          label={
            <FormattedMessage
              id="additionalForm.formItem.taxID"
              defaultMessage="Tax ID"
            />
          }
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input
            placeholder={formatMessage({
              id: 'additionalForm.formItem.taxID',
              defaultMessage: 'Tax ID',
            })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
