// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file api/billing_new/dto_product_new.proto (package com.diagnocat.billing_new, syntax proto3)
/* eslint-disable */

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Service } from "./dto_services_new_pb.js";
import { Created, DeletedNew, RevisionNew } from "../../model/dto_base_pb.js";

/**
 * @generated from enum com.diagnocat.billing_new.ProductStateStatus
 */
export const ProductStateStatus = proto3.makeEnum(
  "com.diagnocat.billing_new.ProductStateStatus",
  [
    {no: 0, name: "ProductStateStatusInvalidValue"},
    {no: 1, name: "ProductStateStatusActive"},
    {no: 2, name: "ProductStateStatusAwaitingActivation"},
    {no: 3, name: "ProductStateStatusDeleted"},
    {no: 4, name: "ProductStateStatusLifeTimeIsOver"},
  ],
);

/**
 * @generated from enum com.diagnocat.billing_new.ProductModelGroupName
 */
export const ProductModelGroupName = proto3.makeEnum(
  "com.diagnocat.billing_new.ProductModelGroupName",
  [
    {no: 0, name: "ProductModelGroupNameInvalidValue"},
    {no: 1, name: "ProductModelGroupNameEmpty"},
    {no: 2, name: "ProductModelGroupNameDentalPractice"},
    {no: 3, name: "ProductModelGroupNameDigitalSegmentation"},
    {no: 4, name: "ProductModelGroupNameMaintenancePlans"},
    {no: 5, name: "ProductModelGroupNameDentalClinic"},
    {no: 6, name: "ProductModelGroupNameImagingCenter"},
    {no: 7, name: "ProductModelGroupNameDigitalLab"},
    {no: 8, name: "ProductModelGroupNameClinicalTestingPlans"},
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.ProductState
 */
export const ProductState = proto3.makeMessageType(
  "com.diagnocat.billing_new.ProductState",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "AccountID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "Model", kind: "message", T: ProductModel },
    { no: 4, name: "Start", kind: "message", T: Timestamp },
    { no: 5, name: "End", kind: "message", T: Timestamp },
    { no: 6, name: "AutoRenewal", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "AutoCharge", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "Paid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "Status", kind: "enum", T: proto3.getEnumType(ProductStateStatus) },
    { no: 10, name: "Services", kind: "message", T: Service, repeated: true },
    { no: 10030, name: "Created", kind: "message", T: Created },
    { no: 10040, name: "Revision", kind: "message", T: RevisionNew },
    { no: 10050, name: "Deleted", kind: "message", T: DeletedNew },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.ProductModel
 */
export const ProductModel = proto3.makeMessageType(
  "com.diagnocat.billing_new.ProductModel",
  () => [
    { no: 1, name: "SKU", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "Description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "StripeProductID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "Services", kind: "message", T: Service, repeated: true },
    { no: 6, name: "BillingZone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "Currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "Price", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "CrossedOutPrice", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "AvailableForAdminsOnly", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "GroupName", kind: "enum", T: proto3.getEnumType(ProductModelGroupName) },
    { no: 12, name: "IsCustom", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 20, name: "Subscription", kind: "message", T: ProductModel_SubscriptionKind, oneof: "Kind" },
    { no: 21, name: "Addon", kind: "message", T: ProductModel_AddonKind, oneof: "Kind" },
    { no: 22, name: "Package", kind: "message", T: ProductModel_PackageKind, oneof: "Kind" },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.ProductModel.SubscriptionKind
 */
export const ProductModel_SubscriptionKind = proto3.makeMessageType(
  "com.diagnocat.billing_new.ProductModel.SubscriptionKind",
  () => [
    { no: 1, name: "LifeTimeMonths", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "LifeTimeDays", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "CanUserAddAddons", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
  {localName: "ProductModel_SubscriptionKind"},
);

/**
 * @generated from message com.diagnocat.billing_new.ProductModel.AddonKind
 */
export const ProductModel_AddonKind = proto3.makeMessageType(
  "com.diagnocat.billing_new.ProductModel.AddonKind",
  [],
  {localName: "ProductModel_AddonKind"},
);

/**
 * @generated from message com.diagnocat.billing_new.ProductModel.PackageKind
 */
export const ProductModel_PackageKind = proto3.makeMessageType(
  "com.diagnocat.billing_new.ProductModel.PackageKind",
  () => [
    { no: 1, name: "LifeTimeMonths", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "LifeTimeDays", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
  {localName: "ProductModel_PackageKind"},
);

