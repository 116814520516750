import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/model/store';

import { organizationUserSharingInfoAdapter } from './userSlice';

const sharedFromSelectors = organizationUserSharingInfoAdapter.getSelectors(
  (state: RootState) => state.user,
);

export const { selectAll: selectAllSharedFromOrganizations } = sharedFromSelectors;

export const selectBillingAccountInfo = createSelector(
  (state: RootState) => state.user.billingAccount,
  (accountInfo) => accountInfo,
);

export const selectUserOrganizationLegacy = createSelector(
  (state: RootState) => state.user.userOrganizationLegacy,
  (organization) => organization,
);

export const settings = createSelector(
  (state: RootState) => state.user.settings,
  (userSettings) => userSettings,
);

export const selectAdminUser = (state: RootState) => state.user.user;

export const selectCurrentUser = (state: RootState) => state.user.currentUser;

export const selectCurrentUserLoadingState = (state: RootState) =>
  state.user.currentUserLoadingState;

export const selectCurrentUserOrganizations = (state: RootState) =>
  state.user.currentUserOrganizations;
