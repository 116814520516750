import { defineMessages } from 'react-intl';

import { UserLocale } from '@/shared/config';

export const languageMessages = defineMessages<UserLocale>({
  'en-US': {
    id: 'language.en',
    defaultMessage: 'English',
  },
  ru: {
    id: 'language.ru',
    defaultMessage: 'Russian',
  },
});
