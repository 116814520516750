import React, { FC, ReactNode } from 'react';
import { Layout } from 'antd';
import cn from 'classnames';

import styles from './layout.module.scss';

type ContentType = {
  children: ReactNode;
  className?: string;
  innerClassName?: string;
};

export const LayoutContent: FC<ContentType> = (props) => {
  const { children, className, innerClassName } = props;

  return (
    <Layout.Content className={cn(styles.content, className)}>
      <div className={cn(styles.contentInner, innerClassName)}>{children}</div>
    </Layout.Content>
  );
};
