import { PropsWithChildren, useEffect, useState } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import { useAppSelector } from '@/shared/hooks';
import { DEFAULT_LOCALE } from '@/shared/config';
import { userModel } from '@/entities/user';

import { getIntlMessages } from '../lib';

export const IntlProvider = ({ children }: PropsWithChildren) => {
  const [messages, setMessages] = useState({});

  const { locale } = useAppSelector(userModel.selectors.settings);

  useEffect(() => {
    const init = async () => {
      const msg = await getIntlMessages(locale);
      setMessages(msg);
    };

    init();
  }, [locale]);

  return (
    <ReactIntlProvider defaultLocale={locale || DEFAULT_LOCALE} locale={locale} messages={messages}>
      {children}
    </ReactIntlProvider>
  );
};
