// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file api/core/svc_adminka.proto (package com.diagnocat.core, syntax proto3)
/* eslint-disable */

import { Empty, proto3 } from "@bufbuild/protobuf";
import { User } from "../../model/dto_user_pb.js";
import { FeatureFlag, Organization, OrganizationContacts } from "../../model/dto_organization_pb.js";

/**
 * @generated from enum com.diagnocat.core.AdminUserListStreamSortBy
 */
export const AdminUserListStreamSortBy = proto3.makeEnum(
  "com.diagnocat.core.AdminUserListStreamSortBy",
  [
    {no: 0, name: "AdminUserListStreamSortByLastName"},
    {no: 1, name: "AdminUserListStreamSortByLastModification"},
    {no: 2, name: "AdminUserListStreamSortByEmail"},
    {no: 3, name: "AdminUserListStreamSortByFirstName"},
    {no: 4, name: "AdminUserListStreamSortByCreatedAt"},
  ],
);

/**
 * @generated from enum com.diagnocat.core.AdminOrganizationListStreamSortBy
 */
export const AdminOrganizationListStreamSortBy = proto3.makeEnum(
  "com.diagnocat.core.AdminOrganizationListStreamSortBy",
  [
    {no: 0, name: "AdminOrganizationListStreamSortByName"},
    {no: 1, name: "AdminOrganizationListStreamSortByLastModification"},
    {no: 2, name: "AdminOrganizationListStreamSortByEmail"},
    {no: 3, name: "AdminOrganizationListStreamSortByCountry"},
    {no: 4, name: "AdminOrganizationListStreamSortByCreatedAt"},
  ],
);

/**
 * @generated from message com.diagnocat.core.AdminUserListStreamReq
 */
export const AdminUserListStreamReq = proto3.makeMessageType(
  "com.diagnocat.core.AdminUserListStreamReq",
  () => [
    { no: 1, name: "ResumeToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "SearchString", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 31, name: "Sort", kind: "message", T: AdminUserListStreamSort, repeated: true },
    { no: 100, name: "StartFromUserID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 101, name: "Limit", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.AdminUserListStreamSort
 */
export const AdminUserListStreamSort = proto3.makeMessageType(
  "com.diagnocat.core.AdminUserListStreamSort",
  () => [
    { no: 1, name: "By", kind: "enum", T: proto3.getEnumType(AdminUserListStreamSortBy) },
    { no: 2, name: "Ascending", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.AdminUserListStreamResp
 */
export const AdminUserListStreamResp = proto3.makeMessageType(
  "com.diagnocat.core.AdminUserListStreamResp",
  () => [
    { no: 1, name: "ResumeToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "InitialUsers", kind: "message", T: AdminUserListStreamResp_UsersList, oneof: "Update" },
    { no: 11, name: "UserUpdated", kind: "message", T: User, oneof: "Update" },
    { no: 101, name: "Ping", kind: "message", T: Empty, oneof: "Update" },
  ],
);

/**
 * @generated from message com.diagnocat.core.AdminUserListStreamResp.UsersList
 */
export const AdminUserListStreamResp_UsersList = proto3.makeMessageType(
  "com.diagnocat.core.AdminUserListStreamResp.UsersList",
  () => [
    { no: 1, name: "Users", kind: "message", T: User, repeated: true },
  ],
  {localName: "AdminUserListStreamResp_UsersList"},
);

/**
 * @generated from message com.diagnocat.core.AdminOrganizationListStreamReq
 */
export const AdminOrganizationListStreamReq = proto3.makeMessageType(
  "com.diagnocat.core.AdminOrganizationListStreamReq",
  () => [
    { no: 1, name: "ResumeToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "SearchString", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 31, name: "Sort", kind: "message", T: AdminOrganizationListStreamSort, repeated: true },
    { no: 100, name: "StartFromOrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 101, name: "Limit", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.AdminOrganizationListStreamSort
 */
export const AdminOrganizationListStreamSort = proto3.makeMessageType(
  "com.diagnocat.core.AdminOrganizationListStreamSort",
  () => [
    { no: 1, name: "By", kind: "enum", T: proto3.getEnumType(AdminOrganizationListStreamSortBy) },
    { no: 2, name: "Ascending", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.AdminOrganizationListStreamResp
 */
export const AdminOrganizationListStreamResp = proto3.makeMessageType(
  "com.diagnocat.core.AdminOrganizationListStreamResp",
  () => [
    { no: 1, name: "ResumeToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "InitialOrganizations", kind: "message", T: AdminOrganizationListStreamResp_OrganizationsList, oneof: "Update" },
    { no: 11, name: "OrganizationUpdated", kind: "message", T: Organization, oneof: "Update" },
    { no: 101, name: "Ping", kind: "message", T: Empty, oneof: "Update" },
  ],
);

/**
 * @generated from message com.diagnocat.core.AdminOrganizationListStreamResp.OrganizationsList
 */
export const AdminOrganizationListStreamResp_OrganizationsList = proto3.makeMessageType(
  "com.diagnocat.core.AdminOrganizationListStreamResp.OrganizationsList",
  () => [
    { no: 1, name: "Organizations", kind: "message", T: Organization, repeated: true },
  ],
  {localName: "AdminOrganizationListStreamResp_OrganizationsList"},
);

/**
 * @generated from message com.diagnocat.core.AdminUserInfoStreamReq
 */
export const AdminUserInfoStreamReq = proto3.makeMessageType(
  "com.diagnocat.core.AdminUserInfoStreamReq",
  () => [
    { no: 1, name: "ResumeToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "UserID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.AdminUserInfoStreamResp
 */
export const AdminUserInfoStreamResp = proto3.makeMessageType(
  "com.diagnocat.core.AdminUserInfoStreamResp",
  () => [
    { no: 1, name: "ResumeToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "HistoricalUser", kind: "message", T: User, oneof: "Update" },
    { no: 12, name: "HistoricalOrganization", kind: "message", T: Organization, oneof: "Update" },
    { no: 13, name: "EndOfHistoricalOrganizations", kind: "message", T: Empty, oneof: "Update" },
    { no: 14, name: "HistoricalSharingInfo", kind: "message", T: AdminUserInfoStreamResp_OrganizationUserSharingInfo, oneof: "Update" },
    { no: 15, name: "EndOfHistoricalUserSharings", kind: "message", T: Empty, oneof: "Update" },
    { no: 21, name: "UpdatedUser", kind: "message", T: User, oneof: "Update" },
    { no: 22, name: "UpdatedOrganization", kind: "message", T: Organization, oneof: "Update" },
    { no: 23, name: "UpdatedSharingInfo", kind: "message", T: AdminUserInfoStreamResp_OrganizationUserSharingInfo, oneof: "Update" },
    { no: 101, name: "Ping", kind: "message", T: Empty, oneof: "Update" },
  ],
);

/**
 * @generated from message com.diagnocat.core.AdminUserInfoStreamResp.OrganizationUserSharingInfo
 */
export const AdminUserInfoStreamResp_OrganizationUserSharingInfo = proto3.makeMessageType(
  "com.diagnocat.core.AdminUserInfoStreamResp.OrganizationUserSharingInfo",
  () => [
    { no: 1, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "OrganizationName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "OrganizationContacts", kind: "message", T: OrganizationContacts },
    { no: 11, name: "AcceptedSharingCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 12, name: "PendingSharingCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 13, name: "CanceledSharingCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
  {localName: "AdminUserInfoStreamResp_OrganizationUserSharingInfo"},
);

/**
 * @generated from message com.diagnocat.core.SetAdminReq
 */
export const SetAdminReq = proto3.makeMessageType(
  "com.diagnocat.core.SetAdminReq",
  () => [
    { no: 1, name: "UserID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "IsAdmin", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetAdminResp
 */
export const SetAdminResp = proto3.makeMessageType(
  "com.diagnocat.core.SetAdminResp",
  () => [
    { no: 1, name: "User", kind: "message", T: User },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetOrganizationFeatureFlagReq
 */
export const SetOrganizationFeatureFlagReq = proto3.makeMessageType(
  "com.diagnocat.core.SetOrganizationFeatureFlagReq",
  () => [
    { no: 1, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "FeatureFlag", kind: "enum", T: proto3.getEnumType(FeatureFlag) },
    { no: 3, name: "Value", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetOrganizationFeatureFlagResp
 */
export const SetOrganizationFeatureFlagResp = proto3.makeMessageType(
  "com.diagnocat.core.SetOrganizationFeatureFlagResp",
  () => [
    { no: 1, name: "Organization", kind: "message", T: Organization },
  ],
);

