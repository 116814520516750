// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_organization_permissions.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message com.diagnocat.model.OrganizationPermissions
 */
export const OrganizationPermissions = proto3.makeMessageType(
  "com.diagnocat.model.OrganizationPermissions",
  () => [
    { no: 1, name: "CanView", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "CanEditInfo", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "CanViewSettings", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "CanEditSettings", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "CanEditBranches", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "CanViewStaff", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "CanEditStaff", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "CanCreateTheirOwnPatients", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "CanCreateOtherDoctorsPatients", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "CanViewMyPatients", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "CanViewAllPatients", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 21, name: "CanEditBillingInfo", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 22, name: "CanViewStatistics", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

