import { useIntl } from "react-intl";
import { Tag, TagProps, Typography } from "antd";

import { i18n } from "@/entities/billing";
import { ProductStateStatus } from 'protocol/api/billing_new/dto_product_new_pb';

import styles from './SubscriptionSummary.module.scss';

const { Text } = Typography; 

export const SUBSCRIPTION_STATUS_COLOR: Record<ProductStateStatus, TagProps['color']> = {
  [ProductStateStatus.ProductStateStatusActive]: 'green',
  [ProductStateStatus.ProductStateStatusAwaitingActivation]: 'orange',
  [ProductStateStatus.ProductStateStatusLifeTimeIsOver]: 'default',
  [ProductStateStatus.ProductStateStatusDeleted]: 'red',
  [ProductStateStatus.ProductStateStatusInvalidValue]: 'default',
};

type SubscriptionSummaryProps = {
  title: string;
  startDate: number;
  endDate: number;
  status: ProductStateStatus;
  isAutoRenewal?: boolean;
  currency?: string;
  price?: number;
};

export const SubscriptionSummary = (props: SubscriptionSummaryProps) => {
  const { title, startDate, endDate, status, currency = 'USD', price = 0, isAutoRenewal = true } = props;

  const { formatMessage, formatDate, formatNumber } = useIntl();

  const cost = formatNumber(price, {
    style: 'currency',
    maximumFractionDigits: 2,
    currencyDisplay: 'narrowSymbol',
    currency,
  });

  return (
    <div className={styles.container}>
      <Text className={styles.title} strong>
        {title}
      </Text>

      {price > 0 &&
        <span>
          {formatMessage(
            { id: 'SubscriptionSummary.cost', defaultMessage: 'Cost: {cost}' },
            {
              cost,
            },
          )}
        </span>
      }

      <span className={styles.date}>
        {formatDate(startDate)}
        {' - '}
        {formatDate(endDate)}
      </span>

      {isAutoRenewal ? (
        <Tag color={SUBSCRIPTION_STATUS_COLOR[status]}>
          {formatMessage(i18n.subscriptionStatusMessages[status])}
        </Tag>
      ) : (
        <Tag color="orange">
          {formatMessage({
            id: 'SubscriptionSummary.expiresOnStatus',
            defaultMessage: 'Expires on',
          })}{' '}
          {formatDate(endDate)}
        </Tag>
      )}
    </div>
  );
};
