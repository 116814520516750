import { createEntityAdapter, createSlice, DeepPartial, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStateType, SliceName } from '@/shared/config';
import { Organization } from 'protocol/model/dto_organization_pb';

export const organizationAdapter = createEntityAdapter<DeepPartial<Organization>>({
  selectId: ({ ID }) => ID,
});

const initialState = {
  organization: {} as Organization,
  loading: 'idle' as LoadingStateType,
};

const organizationSlice = createSlice({
    name: SliceName.organization,
    initialState,
    reducers: {
      setOrganization: (state, { payload }: PayloadAction<Organization>) => {
        state.organization = payload;
      },
      setNewestOrganization: (state, { payload }: PayloadAction<Organization>) => {
        if (payload.Deleted?.Deleted) {
          state.organization = {} as Organization;
        }

        const currentRevisionNumber =
          state.organization?.Revision?.Number ?? 0;
        const payloadRevisionNumber = payload.Revision?.Number ?? 0;

        if (payloadRevisionNumber > currentRevisionNumber) {
          state.organization = payload;
        }
      },
      setLoading: (state, { payload }: PayloadAction<LoadingStateType>) => {
        state.loading = payload;
      },
      removeOrganization: (state) => {
        state.organization = {} as Organization;
      },
    },
  });

export const { actions } = organizationSlice;

export default organizationSlice.reducer;
