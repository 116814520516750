import { Card, Col, Descriptions, Row, Space, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { Layout } from '@/shared/ui';
import { useAccountInfoStream } from '@/entities/billing';
import { Header } from '@/widgets/header';
import { Footer } from '@/widgets/footer';
import { BillingHistory } from '@/widgets/billingHistory';
import { CurrentSubscription } from '@/widgets/currentSubscription';
import { Packages } from '@/widgets/packages';
import { BillingInfo } from '@/widgets/billingInfo';
import { OrganizationUserRoleType } from 'protocol/model/dto_organization_pb';
import { AccountStripeSwitch } from '@/features/accountStripeSwitch';
import { useAppSelector } from '@/shared/hooks';
import { organizationModel } from '@/entities/organization';
import { FeatureFlags } from '@/widgets/featureFlags';
import { SelectBillingZone } from '@/widgets/selectBillingZone';

import { useOrganizationStream } from './hooks';

const { Title } = Typography;

export const Organization = () => {
  const { organizationID } = useParams<{ organizationID: string }>();
  const { formatDate } = useIntl();

  const organization = useAppSelector(organizationModel.selectors.selectOrganization);
  const loading = useAppSelector(organizationModel.selectors.selectOrganizationLoading);

  useOrganizationStream(organizationID);

  const currentOwner = organization?.UserRoles?.find(({ Roles }) =>
    Roles.some(
      (role) => role === OrganizationUserRoleType.OrganizationUserRoleTypeOwner,
    ),
  );

  useAccountInfoStream(organizationID);

  return (
    <Layout>
      <Header />

      <Layout.Content>
        <Row gutter={16} style={{ rowGap: 24 }}>
          <Col md={24} lg={12}>
            <Space
              direction="vertical"
              size="large"
              style={{ display: 'flex' }}
            >
              <Card
                style={{ height: '100%' }}
                title={
                  <Title level={3} style={{ marginBottom: 0 }}>
                    <FormattedMessage
                      id="organization.title"
                      defaultMessage="Organization Information"
                    />
                  </Title>
                }
                bordered={false}
                loading={loading !== 'succeeded'}
                extra={<AccountStripeSwitch />}
              >
                <Descriptions column={1} size="small">
                  <Descriptions.Item
                    label={
                      <FormattedMessage
                        id="organization.name"
                        defaultMessage="Name"
                      />
                    }
                  >
                    {organization?.Name}
                  </Descriptions.Item>

                  <Descriptions.Item
                    label={
                      <FormattedMessage
                        id="organization.id"
                        defaultMessage="ID"
                      />
                    }
                  >
                    {organization?.ID}
                  </Descriptions.Item>

                  <Descriptions.Item
                    label={
                      <FormattedMessage
                        id="global.created"
                        defaultMessage="Created"
                      />
                    }
                  >
                    {organization?.Created?.At?.seconds &&
                      formatDate(
                        new Date(
                          Number(organization?.Created?.At?.seconds) * 1000,
                        ),
                        {
                          year: 'numeric',
                          month: 'long',
                          day: '2-digit',
                        },
                      )}
                  </Descriptions.Item>

                  <Descriptions.Item
                    label={
                      <FormattedMessage
                        id="organization.owner.name"
                        defaultMessage="Owner name"
                      />
                    }
                  >
                    {currentOwner &&
                      `${currentOwner.PersonalData.FirstName} ${currentOwner.PersonalData.LastName}`}
                  </Descriptions.Item>

                  <Descriptions.Item
                    label={
                      <FormattedMessage
                        id="organization.owner.email"
                        defaultMessage="Owner email"
                      />
                    }
                  >
                    {currentOwner?.PersonalData?.Email}
                  </Descriptions.Item>
                </Descriptions>

                <SelectBillingZone />
              </Card>

              <BillingInfo />

              <BillingHistory />
            </Space>
          </Col>

          <Col md={24} lg={12}>
            <Space
              direction="vertical"
              size="large"
              style={{ display: 'flex' }}
            >
              <CurrentSubscription organizationID={organizationID} />

              <Packages organizationID={organizationID} />

              <FeatureFlags />
            </Space>
          </Col>
        </Row>
      </Layout.Content>

      <Footer />
    </Layout>
  );
};
