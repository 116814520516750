// @generated by protoc-gen-connect-web v0.6.0 with parameter "target=ts,ts_nocheck=false"
// @generated from file api/auth/svc_authentication.proto (package com.diagnocat.auth, syntax proto3)
/* eslint-disable */

import { Empty, MethodKind } from "@bufbuild/protobuf";
import { AuthState } from "./dto_auth_pb.js";
import { CheckInviteTokenReq, CheckInviteTokenResp, GetTTLsResp, LogoutReq, Recover_ByEmail_1_StartReq, Recover_ByEmail_2_SetPasswordReq, RefreshResp, SignInReq, SignUp_ByOneself_1_SendUserInfoReq, SignUp_ByOneself_2_ConfirmIdentityReq, SignUp_ByOneself_3_SetPasswordReq, SignUp_ViaOrganizationInviteReq, SignUp_ViaSalesmanInviteReq, SignUp_ViaSharedPatientReq } from "./svc_authentication_pb.js";

/**
 * @generated from service com.diagnocat.auth.Authentication
 */
export const Authentication = {
  typeName: "com.diagnocat.auth.Authentication",
  methods: {
    /**
     * @generated from rpc com.diagnocat.auth.Authentication.GetState
     */
    getState: {
      name: "GetState",
      I: Empty,
      O: AuthState,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.diagnocat.auth.Authentication.CheckInviteToken
     */
    checkInviteToken: {
      name: "CheckInviteToken",
      I: CheckInviteTokenReq,
      O: CheckInviteTokenResp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.diagnocat.auth.Authentication.Logout
     */
    logout: {
      name: "Logout",
      I: LogoutReq,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.diagnocat.auth.Authentication.Refresh
     */
    refresh: {
      name: "Refresh",
      I: Empty,
      O: RefreshResp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.diagnocat.auth.Authentication.GetTTLs
     */
    getTTLs: {
      name: "GetTTLs",
      I: Empty,
      O: GetTTLsResp,
      kind: MethodKind.Unary,
    },
    /**
     *
     * UserBadRequestError:
     * - Code: email_taken (user with this email is already registered)
     *
     * @generated from rpc com.diagnocat.auth.Authentication.SignUp_ByOneself_1_SendUserInfo
     */
    signUp_ByOneself_1_SendUserInfo: {
      name: "SignUp_ByOneself_1_SendUserInfo",
      I: SignUp_ByOneself_1_SendUserInfoReq,
      O: AuthState,
      kind: MethodKind.Unary,
    },
    /**
     *
     * UserBadRequestError:
     * - Code: code_retries_limit (too many attempts)
     * - Code: code_is_expired (code lifetime exceeded)
     * - Code: wrong_code (code is incorrect)
     * - Code: already_registered (user is already registered)
     *
     * @generated from rpc com.diagnocat.auth.Authentication.SignUp_ByOneself_2_ConfirmIdentity
     */
    signUp_ByOneself_2_ConfirmIdentity: {
      name: "SignUp_ByOneself_2_ConfirmIdentity",
      I: SignUp_ByOneself_2_ConfirmIdentityReq,
      O: AuthState,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.diagnocat.auth.Authentication.SignUp_ByOneself_3_SetPassword
     */
    signUp_ByOneself_3_SetPassword: {
      name: "SignUp_ByOneself_3_SetPassword",
      I: SignUp_ByOneself_3_SetPasswordReq,
      O: AuthState,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.diagnocat.auth.Authentication.SignUp_ViaSharedPatient
     */
    signUp_ViaSharedPatient: {
      name: "SignUp_ViaSharedPatient",
      I: SignUp_ViaSharedPatientReq,
      O: AuthState,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.diagnocat.auth.Authentication.SignUp_ViaOrganizationInvite
     */
    signUp_ViaOrganizationInvite: {
      name: "SignUp_ViaOrganizationInvite",
      I: SignUp_ViaOrganizationInviteReq,
      O: AuthState,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.diagnocat.auth.Authentication.SignUp_ViaSalesmanInvite
     */
    signUp_ViaSalesmanInvite: {
      name: "SignUp_ViaSalesmanInvite",
      I: SignUp_ViaSalesmanInviteReq,
      O: AuthState,
      kind: MethodKind.Unary,
    },
    /**
     *
     * UserBadRequestError:
     * - Code: reset_invalid_creds (user with this email is already registered)
     *
     * @generated from rpc com.diagnocat.auth.Authentication.Recover_ByEmail_1_Start
     */
    recover_ByEmail_1_Start: {
      name: "Recover_ByEmail_1_Start",
      I: Recover_ByEmail_1_StartReq,
      O: AuthState,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.diagnocat.auth.Authentication.Recover_ByEmail_2_SetPassword
     */
    recover_ByEmail_2_SetPassword: {
      name: "Recover_ByEmail_2_SetPassword",
      I: Recover_ByEmail_2_SetPasswordReq,
      O: AuthState,
      kind: MethodKind.Unary,
    },
    /**
     *
     * UserBadRequestError:
     * - Code: ErrInvalidCreds (either password or email is wrong)
     *
     * @generated from rpc com.diagnocat.auth.Authentication.SignIn
     */
    signIn: {
      name: "SignIn",
      I: SignInReq,
      O: AuthState,
      kind: MethodKind.Unary,
    },
  }
} as const;

