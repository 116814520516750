import { Select } from 'antd';
import { useIntl } from 'react-intl';

import { UserLocale } from '@/shared/config';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { userModel } from '@/entities/user';

import { AVAILABLE_LOCALES, i18n } from '../config';

const { Option } = Select;

export const LanguageSwitcher = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const { locale } = useAppSelector(userModel.selectors.settings);

  const handleLanguageChange = (value: UserLocale) => {
    dispatch(userModel.thunks.setSettings({ locale: value }));
  };

  return (
    <Select
      defaultValue={locale}
      value={locale}
      style={{ width: 120 }}
      onChange={handleLanguageChange}
    >
      {AVAILABLE_LOCALES.map((availableLocale) => (
        <Option key={availableLocale} value={availableLocale}>
          {formatMessage(i18n.languageMessages[availableLocale])}
        </Option>
      ))}
    </Select>
  );
};
