import React, { FC, useCallback, useState } from 'react';
import { notification, Switch } from 'antd';
import { useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { billingModel } from '@/entities/billing';

import styles from './AccountStripeSwitch.module.scss';

export const AccountStripeSwitch: FC = () => {
  const [loading, setLoading] = useState(false);

  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { ID: accountID, WithoutStripeInvoices } = useAppSelector(billingModel.selectors.selectAccountInfo);

  const toggleStripe = useCallback(
    (value: boolean) => {
      setLoading(true);

      dispatch(billingModel.thunks.toggleAccountStripe({ AccountID: accountID, WithoutStripeInvoices: !value }))
        .catch((error) => {
          notification.error({ message: error?.message });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [accountID, dispatch],
  );
  
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    return null;
  }

  return (
    <div className={styles.container}>
      <Switch
        loading={loading}
        onChange={toggleStripe}
        defaultChecked={!WithoutStripeInvoices}
        checked={!WithoutStripeInvoices}
      />
      {formatMessage({ id: 'accountStripeSwitchLabel', defaultMessage: 'Stripe' })}
    </div>
  );
};

