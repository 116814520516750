import { FC, useState } from 'react';
import { Alert, Button, Checkbox, Form, Input } from 'antd';
import { useLocation, useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import { Code, ConnectError } from '@bufbuild/connect';

import { AuthState_AuthStatus } from 'protocol/api/auth/dto_auth_pb';
import { useAppDispatch } from '@/shared/hooks';
import { authModel } from '@/entities/auth';
import { PATHS } from "@/shared/config";

import styles from './SignIn.module.scss';

export const SignIn: FC = () => {
  const [loading, setLoading] = useState(false);
  const [globalError, setGlobalError] = useState<string>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { formatMessage } = useIntl();

  const from = (location?.state as { from: Location })?.from;

  const onSubmit = async (data: { email: string; password: string }) => {
    const { email, password } = data;

    setLoading(true);

    try {
      const { Status } = await dispatch(
        authModel.thunks.signIn({ Email: email, Password: password }),
      ).unwrap();


      if (Status === AuthState_AuthStatus.SUCCESSFUL) {
        navigate(from || PATHS.main);
      }

    } catch (error) {
      const { code, rawMessage } = error as ConnectError;
      const parsedMessage = JSON.parse(rawMessage);
      const isInvalidCreds = code === Code.FailedPrecondition &&
        parsedMessage.code === 'login_invalid_creds';

      const invalidCredsError = formatMessage({
        id: 'signIn.invalidCredsError',
        defaultMessage:
          'Your password is incorrect or this account does not exist.',
      });

      if (isInvalidCreds) {
        setGlobalError(invalidCredsError);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Form
          name="sign-in"
          layout='vertical'
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onChange={() => setGlobalError(undefined)}
          onFinish={onSubmit}
          disabled={loading}
        >
          <Form.Item
            label={formatMessage({ id: 'signIn.label.email', defaultMessage: 'Email' })}
            name="email"
            rules={[{ required: true, message: formatMessage({ id: 'signIn.validation.email', defaultMessage: 'Please input your Email!' }) }]}
          >
            <Input type="email" autoComplete="email" inputMode="email" />
          </Form.Item>

          <Form.Item
            label={formatMessage({ id: 'signIn.label.password', defaultMessage: 'Password' })}
            name="password"
            rules={[{ required: true, message: formatMessage({ id: 'signIn.validation.password', defaultMessage: 'Please input your password!' }) }]}
          >
            <Input.Password autoComplete="current-password" />
          </Form.Item>

          <Form.Item
            name="remember"
            valuePropName="checked"
          >
            <Checkbox>{formatMessage({ id: 'signIn.label.remember', defaultMessage: 'Remember me' })}</Checkbox>
          </Form.Item>

          {globalError ? <Form.Item>
            <Alert type='error' message={globalError} showIcon />
          </Form.Item> : null}
 
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {formatMessage({ id: 'signIn.submitButton', defaultMessage: 'Submit' })}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
