// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Home-module__container--aektXPk0{gap:24px;padding-top:24px;padding-bottom:24px}.Home-module__pageHeader--A2XIGV8-{display:flex;align-items:center;gap:12px}.Home-module__search--x8i5teG1{flex-grow:1}", "",{"version":3,"sources":["webpack://./src/pages/Home/Home.module.scss"],"names":[],"mappings":"AAAA,kCACE,QAAA,CACA,gBAAA,CACA,mBAAA,CAGF,mCACE,YAAA,CACA,kBAAA,CACA,QAAA,CAGF,+BACE,WAAA","sourcesContent":[".container {\n  gap: 24px;\n  padding-top: 24px;\n  padding-bottom: 24px;\n}\n\n.pageHeader {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n}\n\n.search {\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Home-module__container--aektXPk0",
	"pageHeader": "Home-module__pageHeader--A2XIGV8-",
	"search": "Home-module__search--x8i5teG1"
};
export default ___CSS_LOADER_EXPORT___;
