import { useSearchParams } from 'react-router-dom';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { useDebounce } from './use-debounce';

export const useSearch = (): {
  search: string;
  setSearch: (search: string) => void;
  debouncedSearch: string;
} => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [search, setSearch] = useState<string>(
    () => searchParams.get('search') || '',
  );

  const debouncedSearch = useDebounce<string>(search);

  const updateParams = useCallback((value: string): void => {
    searchParams.set('search', value);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    updateParams(debouncedSearch);
  }, [debouncedSearch, updateParams]);

  return {
    search,
    setSearch,
    debouncedSearch,
  };
};

