export const getInitials = (name: string): string => {
  if (!name.trim()) {
    return '';
  }

  const parts = name.split(' ').filter((el) => el);

  if (parts.length === 1) {
    return parts[0][0].toUpperCase();
  }

  return parts[0][0].toUpperCase() + parts[parts.length - 1][0].toUpperCase();
};
