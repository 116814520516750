import React, { FC, useState } from 'react';
import { Form, Input, Modal, notification, Select, Typography } from 'antd';
import { useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { userModel } from '@/entities/user';
import { OrganizationType } from 'protocol/model/dto_organization_pb';
import { organizationTypeDefaultMessages } from '@/shared/config/i18n';

const { Title } = Typography;

type CreateOrganizationModalProps = {
  isOpen: boolean;
  onCloseHandler: () => void;
};

type CreateOrganizationFormFields = {
  companyName: string;
  companyType: OrganizationType;
};

type OptionsType = {
  value: OrganizationType;
  label: string;
};

export const CreateOrganizationModal: FC<CreateOrganizationModalProps> = (
  props,
) => {
  const { isOpen, onCloseHandler } = props;

  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState<boolean>(false);

  const currentUser = useAppSelector(userModel.selectors.selectCurrentUser);

  const [form] = Form.useForm<CreateOrganizationFormFields>();

  const organizationTypeOptions: OptionsType[] = [
    {
      label: formatMessage(
        organizationTypeDefaultMessages[
          OrganizationType.OrganizationTypeClinic
        ],
      ),
      value: OrganizationType.OrganizationTypeClinic,
    },
    {
      label: formatMessage(
        organizationTypeDefaultMessages[
          OrganizationType.OrganizationTypeSoloPractitioner
        ],
      ),
      value: OrganizationType.OrganizationTypeSoloPractitioner,
    },
    {
      label: formatMessage(
        organizationTypeDefaultMessages[
          OrganizationType.OrganizationTypeDiagnosticCenter
        ],
      ),
      value: OrganizationType.OrganizationTypeDiagnosticCenter,
    },
  ];

  const handleSubmit = () =>
    form.validateFields().then(async (values) => {
      const { companyName, companyType } = values;

      try {
        setLoading(true);

        const { Organization } = await dispatch(
          userModel.thunks.createOrganization({
            OwnerID: currentUser.ID,
            Name: companyName,
            Type: companyType,
          }),
        ).unwrap();

        if (Organization?.ID) {
          dispatch(userModel.actions.addNewOrganization(Organization));
        }
        onCloseHandler();
      } catch (error) {
        notification.error({ message: error?.message });
      } finally {
        setLoading(false);
      }
    });

  return (
    <Modal
      open={isOpen}
      centered
      closable={false}
      okText={formatMessage({ id: 'global.add', defaultMessage: 'Add' })}
      cancelText={formatMessage({
        id: 'global.cancel',
        defaultMessage: 'Cancel',
      })}
      onCancel={onCloseHandler}
      onOk={handleSubmit}
      confirmLoading={loading}
    >
      <Title level={3}>
        {formatMessage({
          id: 'organization.addOrganization.title',
          defaultMessage: 'Add organization',
        })}
      </Title>

      <Title level={5}>
        {formatMessage(
          {
            id: 'organization.addOrganization.message',
            defaultMessage:
              'You are going to add organization for a client {client} ({clientEmail})',
          },
          {
            client: `${currentUser?.PersonalData?.FirstName} ${currentUser?.PersonalData?.LastName}`,
            clientEmail: currentUser?.PersonalData?.Emails[0],
          },
        )}
      </Title>

      <Form
        name="createOrganizationForm"
        form={form}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          name="companyName"
          label={formatMessage({
            id: 'organization.create.companyName.label',
            defaultMessage: 'Company name',
          })}
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'forms.required',
                defaultMessage: 'Field is required',
              }),
            },
          ]}
        >
          <Input
            placeholder={formatMessage({
              id: 'organization.create.companyName',
              defaultMessage: 'Organization name',
            })}
          />
        </Form.Item>

        <Form.Item
          name="companyType"
          label={formatMessage({
            id: 'organization.create.companyType.label',
            defaultMessage: 'Company type',
          })}
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'forms.required',
                defaultMessage: 'Field is required',
              }),
            },
          ]}
        >
          <Select options={organizationTypeOptions} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
