import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { AdminUserInfoStreamResp_OrganizationUserSharingInfo } from 'protocol/api/core/svc_adminka_pb';
import { User } from 'protocol/model/dto_user_pb';
import { Account } from 'protocol/api/billing/dto_account_pb';
import { Organization } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { LoadingStateType } from '@/shared/config';

import { getInitialUserSettings } from '../lib/getInitialUserSettings';

import { setSettings } from './userSlice.thunks';

export type UserOrganizationLegacy = {
  id: string;
  name: string;
  isCurrentUserAdmin: boolean;
};

export const organizationUserSharingInfoAdapter =
  createEntityAdapter<AdminUserInfoStreamResp_OrganizationUserSharingInfo>({
    selectId: (sharedOrganization) => sharedOrganization.OrganizationID,
  });

const initialState = {
  // TODO: Rename user to admin or move into another slice
  user: {} as User,
  currentUser: {} as User,
  currentUserOrganizations: [] as Organization[],
  userOrganizationLegacy: {
    id: '',
    name: '',
    isCurrentUserAdmin: true,
  },
  billingAccount: {} as Account,
  settings: getInitialUserSettings(),
  loading: false,
  currentUserLoadingState: 'idle' as LoadingStateType,
};

const userSlice = createSlice({
  name: 'user',
  initialState: organizationUserSharingInfoAdapter.getInitialState(initialState),
  reducers: {
    // I don't know what happened to the types
    // @ts-ignore
    updateOne: organizationUserSharingInfoAdapter.updateOne,
    // @ts-ignore
    setMany: organizationUserSharingInfoAdapter.setMany,
    addUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    resetUser: (state) => {
      state.user = {} as User;
    },
    setCurrentUser: (state, { payload }: PayloadAction<User>) => {
      state.currentUser = payload;
    },
    setCurrentUserOrganizations: (
      state,
      { payload }: PayloadAction<Organization[]>,
    ) => {
      state.currentUserOrganizations = payload;
    },
    addNewOrganization: (state, { payload }: PayloadAction<Organization>) => {
      state.currentUserOrganizations.push(payload);
    },
    setUserOrganizationLegacy: (
      state,
      action: PayloadAction<UserOrganizationLegacy>,
    ) => {
      state.userOrganizationLegacy = action.payload;
    },
    setBillingAccount: (state, action: PayloadAction<Account>) => {
      state.billingAccount = action.payload;
    },
    resetCurrentUser: (state) => {
      state.currentUser = {} as User;
    },
    resetCurrentUserOrganizations: (state) => {
      state.currentUserOrganizations = [];
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setCurrentUserLoadingState: (
      state,
      { payload }: PayloadAction<LoadingStateType>,
    ) => {
      state.currentUserLoadingState = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSettings.fulfilled, (state, action) => {
      state.settings = { ...state.settings, ...action.payload };
    });
  },
});

export const { actions } = userSlice;

export default userSlice.reducer;
