import { FC } from 'react';
import { Outlet } from 'react-router';

import { useUserDataStream } from '../hooks/useUserDataStream';

export const ProtectedRoutes: FC = () => {
  useUserDataStream();

  return <Outlet />;
};
