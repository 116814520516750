// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PackageBar-module__container--3WOgGs0K{display:flex;align-items:flex-end;color:var(--grey9)}.PackageBar-module__label--j2UuZ93L{font-size:16px;width:200px;flex-shrink:0}.PackageBar-module__progressContainer--iVBe\\+rGf{display:flex;flex-direction:column;width:100%;color:var(--grey9)}\n\n.PackageBar-module__progressText--Gvda1yZW{margin-left:auto;color:var(--grey9)}\n\n[dir=\"rtl\"] .PackageBar-module__progressText--Gvda1yZW{margin-left:0;margin-right:auto}", "",{"version":3,"sources":["webpack://./src/features/packageBar/ui/packageBar/PackageBar.module.scss"],"names":[],"mappings":"AAAA,wCACE,YAAA,CACA,oBAAA,CACA,kBAAA,CAGF,oCACE,cAAA,CACA,WAAA,CACA,aAAA,CAGF,iDACE,YAAA,CACA,qBAAA,CACA,UAAA,CACA,kBAAA;;AAGF,2CACE,gBAAA,CACA,kBAAA;;AAFF,uDACE,aAAA,CAAA,iBACA","sourcesContent":[".container {\n  display: flex;\n  align-items: flex-end;\n  color: var(--grey9);\n}\n\n.label {\n  font-size: 16px;\n  width: 200px;\n  flex-shrink: 0;\n}\n\n.progressContainer {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  color: var(--grey9);\n}\n\n.progressText {\n  margin-left: auto;\n  color: var(--grey9);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "PackageBar-module__container--3WOgGs0K",
	"label": "PackageBar-module__label--j2UuZ93L",
	"progressContainer": "PackageBar-module__progressContainer--iVBe+rGf",
	"progressText": "PackageBar-module__progressText--Gvda1yZW"
};
export default ___CSS_LOADER_EXPORT___;
