import { Service } from 'protocol/api/billing_new/dto_services_new_pb';

export const serviceIsVisible = (service: Service) => {
  const serviceKind = service.Kind;

  if (serviceKind.case === 'Countable') return serviceKind.value.Leftover && service.Visible;

  return false;
};

