// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_base.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum com.diagnocat.model.CalledVia
 */
export const CalledVia = proto3.makeEnum(
  "com.diagnocat.model.CalledVia",
  [
    {no: 0, name: "CalledViaUnspecified"},
    {no: 1, name: "CalledViaClient"},
    {no: 2, name: "CalledViaPartnerAPI"},
    {no: 3, name: "CalledViaMLPipelines"},
  ],
);

/**
 * @generated from enum com.diagnocat.model.Gender
 */
export const Gender = proto3.makeEnum(
  "com.diagnocat.model.Gender",
  [
    {no: 0, name: "GenderUnspecified"},
    {no: 1, name: "GenderMale"},
    {no: 2, name: "GenderFemale"},
    {no: 3, name: "GenderOther"},
  ],
);

/**
 * @generated from enum com.diagnocat.model.OptionalBoolean
 */
export const OptionalBoolean = proto3.makeEnum(
  "com.diagnocat.model.OptionalBoolean",
  [
    {no: 0, name: "OptionalBooleanUnspecified"},
    {no: 1, name: "OptionalBooleanFalse"},
    {no: 2, name: "OptionalBooleanTrue"},
  ],
);

/**
 * @generated from message com.diagnocat.model.Revision
 */
export const Revision = proto3.makeMessageType(
  "com.diagnocat.model.Revision",
  () => [
    { no: 1, name: "Number", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "UpdatedAt", kind: "message", T: Timestamp },
    { no: 3, name: "UserID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.model.Created
 */
export const Created = proto3.makeMessageType(
  "com.diagnocat.model.Created",
  () => [
    { no: 1, name: "By", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "At", kind: "message", T: Timestamp },
    { no: 3, name: "Via", kind: "enum", T: proto3.getEnumType(CalledVia) },
  ],
);

/**
 * @generated from message com.diagnocat.model.Deleted
 */
export const Deleted = proto3.makeMessageType(
  "com.diagnocat.model.Deleted",
  () => [
    { no: 1, name: "Deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "By", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "At", kind: "message", T: Timestamp },
    { no: 4, name: "Via", kind: "enum", T: proto3.getEnumType(CalledVia) },
  ],
);

/**
 * @generated from message com.diagnocat.model.Attribute
 */
export const Attribute = proto3.makeMessageType(
  "com.diagnocat.model.Attribute",
  () => [
    { no: 1, name: "Key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.model.PersonalData
 */
export const PersonalData = proto3.makeMessageType(
  "com.diagnocat.model.PersonalData",
  () => [
    { no: 1, name: "FirstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "LastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "DateOfBirth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "Emails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "Phones", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.model.RevisionNew
 */
export const RevisionNew = proto3.makeMessageType(
  "com.diagnocat.model.RevisionNew",
  () => [
    { no: 1, name: "Number", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "By", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "At", kind: "message", T: Timestamp },
    { no: 4, name: "Via", kind: "enum", T: proto3.getEnumType(CalledVia) },
  ],
);

/**
 * @generated from message com.diagnocat.model.DeletedNew
 */
export const DeletedNew = proto3.makeMessageType(
  "com.diagnocat.model.DeletedNew",
  () => [
    { no: 1, name: "IsDeleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "By", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "At", kind: "message", T: Timestamp },
    { no: 4, name: "Via", kind: "enum", T: proto3.getEnumType(CalledVia) },
  ],
);

