import { useEffect, useState } from 'react';
import sortBy from 'lodash/sortBy';

type OptionsType = {
  value: string;
  label: string;
}

const getBillingZones = async () =>
  import('../config/billingZones.json').then(
    (billingZones) => billingZones.default,
  );

const makeBillingZoneOptions = (billingZones: string[]) =>
  sortBy(
    billingZones.map((billingZone) => ({
      value: billingZone,
      label: billingZone,
    })),
    'label',
  );

export const useBillingZonesOptions = () => {
  const [allBillingZones, setAllBillingZones] = useState<OptionsType[]>([]);

  useEffect(() => {
    const getAllBillingZones = async () => {
      const billingZones = await getBillingZones();

      setAllBillingZones(makeBillingZoneOptions(Object.keys(billingZones)));
    };

    getAllBillingZones();
  }, []);

  return allBillingZones;
};
