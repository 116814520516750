import { Button, Card, Empty, Space, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';

import { useAppSelector } from '@/shared/hooks';
import {
  billingModel,
  serviceIsVisible,
  useRemovePackageModal,
} from '@/entities/billing';
import { PackageBar } from '@/features/packageBar';
import { SubscriptionSummary } from '@/features/subscriptionSummary';
import { PATHS } from '@/shared/config';

const { Title } = Typography;

const selectAccountInfoLoadingState =
  billingModel.selectors.getLoadingStateSelector('accountInfo');

type PackageProps = {
  organizationID: string;
};

// TODO: Remove Number constructor when in protocol fix bigint
export const Packages = (props: PackageProps) => {
  const { organizationID } = props;

  const packages = useAppSelector(billingModel.selectors.selectPackages);
  const accountInfoLoadingState = useAppSelector(selectAccountInfoLoadingState);
  const { ID: accountID } = useAppSelector(
    billingModel.selectors.selectAccountInfo,
  );
  
  const openRemovePackageModal = useRemovePackageModal();

  return (
    <Card
      style={{ height: '100%' }}
      title={
        <Title level={3} style={{ marginBottom: 0 }}>
          <FormattedMessage id="packages.title" defaultMessage="Packages" />
        </Title>
      }
      bordered={false}
      extra={
        <Link to={generatePath(PATHS.addPackage, { organizationID })}>
          <FormattedMessage id="addOns.add" defaultMessage="Add" />
        </Link>
      }
      loading={accountInfoLoadingState !== 'succeeded'}
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        {packages.length > 0 ? (
          packages.map((packageItem) => (
            <Space
              key={packageItem.ID}
              direction="vertical"
              size="middle"
              style={{ display: 'flex' }}
            >
              <SubscriptionSummary
                title={packageItem?.Model?.Name}
                startDate={Number(packageItem?.Start?.seconds ?? 0) * 1000}
                endDate={Number(packageItem?.End?.seconds ?? 0) * 1000}
                status={packageItem.Status}
              />

              {packageItem.Services.filter(serviceIsVisible).map((service) => (
                <PackageBar
                  key={`${packageItem.ID}-${service.Types.toString()}-${service.Kind.case}-${service.Group}-${service.Kind.value.toJsonString()}`}
                  packageItem={service}
                />
              ))}

              <Button
                key="remove"
                type="primary"
                onClick={() =>
                  openRemovePackageModal({
                    packageID: packageItem.ID,
                    accountID,
                  })
                }
              >
                <FormattedMessage
                  id="currentSubscription.removeSubscription"
                  defaultMessage="Remove"
                />
              </Button>
            </Space>
          ))
        ) : (
          <Empty
            description={
              <FormattedMessage
                id="packages.noPackages"
                defaultMessage="No packages"
              />
            }
          />
        )}
      </Space>
    </Card>
  );
};
