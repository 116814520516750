// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js!./shared/styles/normalize.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --transition-time: 300ms;\n}\n\nhtml,\nbody,\n#root {\n  height: 100%;\n  /*position: relative;*/\n  /*z-index: 1;*/\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\nhr {\n  border: none;\n}\n\na {\n  text-decoration: none;\n  color: var(--grey9);\n}\n\na:hover {\n  text-decoration: underline;\n}\n\nbutton {\n  all: unset;\n}\n\n@media print {\n  body {\n    height: auto;\n  }\n\n  .intercom-lightweight-app {\n    display: none;\n  }\n}\n\n@page {\n  size: a4;\n  margin: 1.5cm 1cm;\n}\n", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,wBAAwB;AAC1B;;AAEA;;;EAGE,YAAY;EACZ,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,aAAa;EACf;AACF;;AAEA;EACE,QAAQ;EACR,iBAAiB;AACnB","sourcesContent":["@import './shared/styles/normalize.css';\n\n:root {\n  --transition-time: 300ms;\n}\n\nhtml,\nbody,\n#root {\n  height: 100%;\n  /*position: relative;*/\n  /*z-index: 1;*/\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\nhr {\n  border: none;\n}\n\na {\n  text-decoration: none;\n  color: var(--grey9);\n}\n\na:hover {\n  text-decoration: underline;\n}\n\nbutton {\n  all: unset;\n}\n\n@media print {\n  body {\n    height: auto;\n  }\n\n  .intercom-lightweight-app {\n    display: none;\n  }\n}\n\n@page {\n  size: a4;\n  margin: 1.5cm 1cm;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
