// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".account-module__space--JMsJqZil{display:flex;gap:16px}.account-module__subTitle--lmARQ5Cn{display:flex;align-items:center;justify-content:space-between;margin-bottom:24px}.account-module__formItem--GTTwZiA7{width:100%}", "",{"version":3,"sources":["webpack://./src/widgets/account/ui/account/account.module.scss"],"names":[],"mappings":"AAAA,iCACE,YAAA,CACA,QAAA,CAGF,oCACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,kBAAA,CAGF,oCACE,UAAA","sourcesContent":[".space {\n  display: flex;\n  gap: 16px\n}\n\n.subTitle {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 24px;\n}\n\n.formItem {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"space": "account-module__space--JMsJqZil",
	"subTitle": "account-module__subTitle--lmARQ5Cn",
	"formItem": "account-module__formItem--GTTwZiA7"
};
export default ___CSS_LOADER_EXPORT___;
