import React from 'react';
import { ToastPosition, TypeOptions } from 'react-toastify';

type ToasterValuesType = {
  heading: React.ReactNode;
  message: React.ReactNode;
  type: TypeOptions;
  autoClose: number;
  position: ToastPosition;
};

type DefaultVaaluesType = {
  toasterValues: ToasterValuesType;
  setToasterValues: (
    heading: React.ReactNode,
    message: React.ReactNode,
    type: TypeOptions,
    autoClose: number,
    position: ToastPosition,
  ) => void;
};

export const defaultValue: DefaultVaaluesType = {
  toasterValues: {
    heading: '',
    message: '',
    type: 'default',
    autoClose: 3000,
    position: 'top-right',
  },
  setToasterValues: (heading, message, type, autoClose, position) => {
    defaultValue.toasterValues.heading = heading;
    defaultValue.toasterValues.message = message;
    defaultValue.toasterValues.type = type;
    defaultValue.toasterValues.autoClose = autoClose;
    defaultValue.toasterValues.position = position;
  },
};

export const ErrorContext = React.createContext(defaultValue);
