import React, { useCallback, useState } from 'react';
import { Form, Select, Modal, Typography, Space, notification } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { billingModel } from '@/entities/billing';
import { i18n } from '@/shared/config';

import { useBillingZonesOptions } from '../hooks/useBillingZonesOptions';

export const SelectBillingZone = () => {
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { ID: accountID, BillingZones } = useAppSelector(
    billingModel.selectors.selectAccountInfo,
  );
  const billingZonesOptions = useBillingZonesOptions();
  const currentBillingZone = BillingZones?.at(0);

  const changeBillingZone = useCallback(
    async (newBillingZone: string) => {
      setLoading(true);

      try {
        await dispatch(
          billingModel.thunks.setAccountBillingZone({
            AccountID: accountID,
            BillingZones: [newBillingZone],
          }),
        ).unwrap();

        notification.success({
          placement: 'bottomLeft',
          message: formatMessage({
            id: 'userProfile.changeBillingZoneSuccessMessage',
            defaultMessage: 'Billing zone successfully changed',
          }),
        });
      } catch (error) {
        notification.error({ message: error?.message });
      } finally {
        setLoading(false);
      }
    },
    [accountID, dispatch, formatMessage],
  );

  const showConfirmChangeBillingZone = (newBillingZone: string) => {
    Modal.confirm({
      // NOTE: For some reason title throw ts error because we use values to get billing zone in warning window
      // @ts-ignore
      title: formatMessage(
        {
          id: 'userProfile.changeBillingZoneConfirm',
          defaultMessage:
            'Are you sure you want to change the billing zone to {billingZone} for this account?',
        },
        {
          billingZone: <b>{newBillingZone}</b>,
        },
      ),
      cancelText: formatMessage(i18n.modal.no),
      okText: formatMessage(i18n.modal.yes),
      onOk() {
        return changeBillingZone(newBillingZone);
      },
    });
  };

  return (
    <Form.Item
      name="billingZone"
      label={
        <FormattedMessage
          id="userProfile.billingZone"
          defaultMessage="Billing Zone"
        />
      }
    >
      <Space direction="vertical">
        <Typography.Text type="warning">
          <FormattedMessage
            id="userProfile.billingZoneWarning"
            defaultMessage="Changing the billing zone will impact the customer's current subscription, package, and financial reporting. To safely implement the change, please remove the existing subscription, change the billing zone and add a new subscription and required packages."
          />
        </Typography.Text>
        <Select
          value={currentBillingZone}
          options={billingZonesOptions}
          loading={loading}
          onChange={showConfirmChangeBillingZone}
        />
      </Space>
    </Form.Item>
  );
};
