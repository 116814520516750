import { useEffect } from 'react';
import { Code, ConnectError } from '@bufbuild/connect';

import api from '@/shared/api/api';
import { useAppDispatch } from '@/shared/hooks';
import { organizationModel } from '@/entities/organization';

let abortController: AbortController;

export const useOrganizationStream = (organizationID: string) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    abortController = new AbortController();

    const openOrganizationStream = async () => {
      dispatch(organizationModel.actions.setLoading('pending'));

      const organizationSettingsStream = api.organization.organizationSettingsStream({ OrganizationID: organizationID }, { signal: abortController.signal });

      try {
        for await (const { Update } of organizationSettingsStream) {
          switch (Update.case) {
            case 'HistoricalOrganization': {
              dispatch(organizationModel.actions.setOrganization(Update.value));
              dispatch(organizationModel.actions.setLoading('succeeded'));
              break;
            }
            
            case 'UpdatedOrganization': {
              dispatch(organizationModel.actions.setNewestOrganization(Update.value));
              break;
            }

            default: {
              // throw
            }
          }
        }
      } catch (error) {
        if (error instanceof ConnectError && error.code !== Code.Canceled) {
          dispatch(organizationModel.actions.setLoading('failed'));
          console.error(error);
        }
      }
    };

    openOrganizationStream();

    return () => {
      dispatch(organizationModel.actions.removeOrganization());
      abortController.abort();
    };
  }, [organizationID]);
};
