import React from 'react';
import { Route as _Route, Routes as ReactRouterRoutes } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { PATHS } from '@/shared/config';
import { SignIn } from '@/pages/SignIn/SignIn';
import { Home } from '@/pages/Home/Home';
import { User } from '@/pages/User/User';
import { AddSubscription } from '@/pages/AddSubscription/AddSubscription';
import { AddPackages } from '@/pages/AddPackages/AddPackages';
import { Organization } from '@/pages/Organization/Organization';

import { ProtectedRoutes } from '../providers/ProtectedRoutes';

const Route = Sentry.withSentryReactRouterV6Routing(_Route);

export const Routes = (): React.ReactElement => (
  <ReactRouterRoutes>
    <Route path={PATHS.signIn} element={<SignIn />} />

    <Route element={<ProtectedRoutes />}>
      <Route path={PATHS.main} element={<Home />} />
      <Route path={PATHS.user} element={<User />} />
      <Route path={PATHS.organization} element={<Organization />} />
      <Route path={PATHS.addSubscription} element={<AddSubscription />} />
      <Route path={PATHS.addPackage} element={<AddPackages />} />
    </Route>
    {/* TODO: Implement error page */}
    {/* <Route path={PATHS.error} element={<ErrorPage />} /> */}
  </ReactRouterRoutes>
);
