// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file api/billing_new/dto_account_new.proto (package com.diagnocat.billing_new, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { Created, DeletedNew, RevisionNew } from "../../model/dto_base_pb.js";

/**
 * @generated from message com.diagnocat.billing_new.Account
 */
export const Account = proto3.makeMessageType(
  "com.diagnocat.billing_new.Account",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "OwnerID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "OrganizationIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "BillingZones", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "Country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "StripeIntegration", kind: "message", T: AccountStripeIntegration },
    { no: 8, name: "WithoutStripeInvoices", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "BillingInformation", kind: "message", T: AccountBillingInformation },
    { no: 10030, name: "Created", kind: "message", T: Created },
    { no: 10040, name: "Revision", kind: "message", T: RevisionNew },
    { no: 10050, name: "Deleted", kind: "message", T: DeletedNew },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.AccountBillingInformation
 */
export const AccountBillingInformation = proto3.makeMessageType(
  "com.diagnocat.billing_new.AccountBillingInformation",
  () => [
    { no: 1, name: "Address", kind: "message", T: Address },
    { no: 2, name: "Phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "CompanyName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "TaxID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.AccountStripeIntegration
 */
export const AccountStripeIntegration = proto3.makeMessageType(
  "com.diagnocat.billing_new.AccountStripeIntegration",
  () => [
    { no: 1, name: "CustomerID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "PaymentMethodID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.billing_new.Address
 */
export const Address = proto3.makeMessageType(
  "com.diagnocat.billing_new.Address",
  () => [
    { no: 1, name: "Region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "City", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "ZIP", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "Address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

