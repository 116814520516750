// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_access.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { Created, Revision } from "./dto_base_pb.js";
import { OrganizationUserRoleType } from "./dto_organization_pb.js";
import { OrganizationJobPosition } from "./dto_organization_job_position_pb.js";

/**
 * @generated from message com.diagnocat.model.Invitation
 */
export const Invitation = proto3.makeMessageType(
  "com.diagnocat.model.Invitation",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "Recipient", kind: "message", T: InvitationRecipient },
    { no: 5, name: "Target", kind: "message", T: InvitationTarget },
    { no: 6, name: "TargetDetails", kind: "message", T: InvitationTargetDetails },
    { no: 7, name: "Status", kind: "enum", T: proto3.getEnumType(Invitation_InvitationStatus) },
    { no: 8, name: "Comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "Secret", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10030, name: "Created", kind: "message", T: Created },
    { no: 10040, name: "Revision", kind: "message", T: Revision },
  ],
);

/**
 * @generated from enum com.diagnocat.model.Invitation.InvitationStatus
 */
export const Invitation_InvitationStatus = proto3.makeEnum(
  "com.diagnocat.model.Invitation.InvitationStatus",
  [
    {no: 0, name: "StatusPending"},
    {no: 1, name: "StatusAccepted"},
    {no: 2, name: "StatusCancelled"},
    {no: 3, name: "StatusDeleted"},
  ],
);

/**
 * @generated from message com.diagnocat.model.InvitationRecipient
 */
export const InvitationRecipient = proto3.makeMessageType(
  "com.diagnocat.model.InvitationRecipient",
  () => [
    { no: 1, name: "UserID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "Email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.model.InvitationTarget
 */
export const InvitationTarget = proto3.makeMessageType(
  "com.diagnocat.model.InvitationTarget",
  () => [
    { no: 1, name: "Organization", kind: "message", T: InvitationTarget_OrganizationMembership, oneof: "Target" },
    { no: 2, name: "Patient", kind: "message", T: InvitationTarget_PatientSharing, oneof: "Target" },
  ],
);

/**
 * @generated from message com.diagnocat.model.InvitationTarget.OrganizationMembership
 */
export const InvitationTarget_OrganizationMembership = proto3.makeMessageType(
  "com.diagnocat.model.InvitationTarget.OrganizationMembership",
  () => [
    { no: 1, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "FirstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "LastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "Roles", kind: "enum", T: proto3.getEnumType(OrganizationUserRoleType), repeated: true },
    { no: 5, name: "JobPositions", kind: "enum", T: proto3.getEnumType(OrganizationJobPosition), repeated: true },
  ],
  {localName: "InvitationTarget_OrganizationMembership"},
);

/**
 * @generated from message com.diagnocat.model.InvitationTarget.PatientSharing
 */
export const InvitationTarget_PatientSharing = proto3.makeMessageType(
  "com.diagnocat.model.InvitationTarget.PatientSharing",
  () => [
    { no: 1, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "InvitationTarget_PatientSharing"},
);

/**
 * @generated from message com.diagnocat.model.InvitationTargetDetails
 */
export const InvitationTargetDetails = proto3.makeMessageType(
  "com.diagnocat.model.InvitationTargetDetails",
  () => [
    { no: 1, name: "OrganizationName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "PatientFirstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "PatientLastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "SenderFirstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "SenderLastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "SenderEmail", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

