import { useIntl } from 'react-intl';
import { Modal } from 'antd';

import { useAppDispatch } from '@/shared/hooks';
import { i18n } from '@/shared/config';

import * as billingModel from '../model';

export const useCancelSubscriptionModal = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  return ({ subscriptionID, accountID }: { subscriptionID: string; accountID: string }) => {
    Modal.confirm({
      title: formatMessage({
        id: 'cancelSubscriptionModal.title',
        defaultMessage: 'Cancel subscription',
      }),
      cancelText: formatMessage(i18n.modal.no),
      okText: formatMessage(i18n.modal.yes),
      content: formatMessage({
        id: 'cancelSubscriptionModal.text',
        defaultMessage: 'Are you sure you want to cancel subscription?',
      }),
      okButtonProps: {
        danger: true,
      },
      centered: true,
      onOk() {
        return dispatch(
          billingModel.thunks.createOrder({
            AccountID: accountID,
            Effect: {
              case: 'SubscriptionSetAutoRenewal',
              value: {
                SubscriptionStateID: subscriptionID,
                IsAutoRenewal: false,
              }
            }
          }),
        );
      },
    });
  };
};

