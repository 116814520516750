export enum UserLocalSettingsKey {
  locale = 'locale',
  view = 'view',
}

export type UserView = 'grid' | 'row';
export type UserLocale = 'en' | 'ru';

export type UserLocalSettings = {
  [UserLocalSettingsKey.locale]: UserLocale;
  [UserLocalSettingsKey.view]: UserView;
};

const DEFAULT_USER_SETTINGS: UserLocalSettings = {
  locale: 'en',
  view: 'row',
};

export const getInitialUserSettings = (): UserLocalSettings =>
  Object.keys(DEFAULT_USER_SETTINGS).reduce(
    (result, key: UserLocalSettingsKey) => {
      const value = localStorage.getItem(key);
      if (value) {
        return {
          ...result,
          [key]: value,
        };
      }

      return result;
    },
    DEFAULT_USER_SETTINGS,
  );
