import { Progress, Typography } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import { Service } from 'protocol/api/billing_new/dto_services_new_pb';
import { GroupNamesDefaultMessage, ServiceNamesDefaultMessage } from '@/shared/config/i18n';

import styles from './PackageBar.module.scss';

const { Text } = Typography;

type PackageBarProps = {
  packageItem: Service
};
// TODO: Remove Number constructor when in protocol fix bigint
export const PackageBar = (props: PackageBarProps) => {
  const { packageItem } = props;

  const { formatMessage } = useIntl();

  const serviceTypes = packageItem.Types;
  const serviceType = serviceTypes.at(0);
  const isGroupService = serviceTypes.length > 1;
  const groupName = isGroupService ? formatMessage(GroupNamesDefaultMessage[packageItem.Group], {amount: 0}) : null;
  const countable = packageItem.Kind.case === 'Countable' && packageItem.Kind.value;

  const limit = Number(countable.Limit);
  const used = limit - Number(countable.Leftover);
  const completed = Math.round((Number(used) / Number(limit)) * 100);

  const isMessageExist = serviceType in ServiceNamesDefaultMessage;

  const label = isMessageExist
    ? formatMessage(ServiceNamesDefaultMessage[serviceType], { amount: Number(limit) })
    : formatMessage({ id:"packageBar.undefinedService", defaultMessage: "Undefined Service" });

  if (!isMessageExist && process.env.REACT_APP_ENVIRONMENT === 'development') {
    console.warn(
      '[DIAGNOCAT]: Please update protocol and/or add missed translations for the new Services',
    );
  } 

  return (
    <div className={styles.container}>
      <span className={styles.label}>
        {isGroupService ? groupName : label}
      </span>

      <div className={styles.progressContainer}>
        <Text className={styles.progressText}>
          <FormattedMessage
            id="packageBar.outOfUsed"
            defaultMessage="{used} out of {limit} used"
            values={{
              used,
              limit,
            }}
          />
        </Text>

        <Progress 
          percent={completed} 
          showInfo={false} 
          strokeColor="#7564ed" 
          style={{ margin: 0 }} 
        />
      </div>
    </div>
  );
};
