import { defineMessages } from 'react-intl';

import { ProductStateStatus } from 'protocol/api/billing_new/dto_product_new_pb';

export const subscriptionStatusMessages = defineMessages<ProductStateStatus>({
  [ProductStateStatus.ProductStateStatusInvalidValue]: {
    id: 'subscriptionStatus.invalid',
    defaultMessage: 'Invalid',
  },
  [ProductStateStatus.ProductStateStatusActive]: {
    id: 'subscriptionStatus.active',
    defaultMessage: 'Active',
  },
  [ProductStateStatus.ProductStateStatusAwaitingActivation]: {
    id: 'subscriptionStatus.awaitingActivation',
    defaultMessage: 'Awaiting activation',
  },
  [ProductStateStatus.ProductStateStatusDeleted]: {
    id: 'subscriptionStatus.deleted',
    defaultMessage: 'Deleted',
  },
  [ProductStateStatus.ProductStateStatusLifeTimeIsOver]: {
    id: 'subscriptionStatus.expired',
    defaultMessage: 'Expired',
  },
});
