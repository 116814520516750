// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file api/core/svc_organization.proto (package com.diagnocat.core, syntax proto3)
/* eslint-disable */

import { Empty, proto3 } from "@bufbuild/protobuf";
import { Organization, OrganizationContacts, OrganizationSettings, OrganizationType } from "../../model/dto_organization_pb.js";
import { Invitation } from "../../model/dto_access_pb.js";
import { User } from "../../model/dto_user_pb.js";
import { OrganizationJobPosition } from "../../model/dto_organization_job_position_pb.js";

/**
 * @generated from message com.diagnocat.core.OrganizationSettingsStreamReq
 */
export const OrganizationSettingsStreamReq = proto3.makeMessageType(
  "com.diagnocat.core.OrganizationSettingsStreamReq",
  () => [
    { no: 1, name: "ResumeToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.OrganizationSettingsStreamResp
 */
export const OrganizationSettingsStreamResp = proto3.makeMessageType(
  "com.diagnocat.core.OrganizationSettingsStreamResp",
  () => [
    { no: 1, name: "ResumeToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "HistoricalOrganization", kind: "message", T: Organization, oneof: "Update" },
    { no: 12, name: "HistoricalInvitation", kind: "message", T: Invitation, oneof: "Update" },
    { no: 13, name: "EndOfHistoricalInvitations", kind: "message", T: Empty, oneof: "Update" },
    { no: 21, name: "UpdatedOrganization", kind: "message", T: Organization, oneof: "Update" },
    { no: 22, name: "UpdatedInvitation", kind: "message", T: Invitation, oneof: "Update" },
    { no: 101, name: "Ping", kind: "message", T: Empty, oneof: "Update" },
  ],
);

/**
 * @generated from message com.diagnocat.core.CreateOrganizationReq
 */
export const CreateOrganizationReq = proto3.makeMessageType(
  "com.diagnocat.core.CreateOrganizationReq",
  () => [
    { no: 1, name: "Name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ParentID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "Type", kind: "enum", T: proto3.getEnumType(OrganizationType) },
    { no: 4, name: "Contacts", kind: "message", T: OrganizationContacts, opt: true },
    { no: 5, name: "OwnerID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.CreateOrganizationResp
 */
export const CreateOrganizationResp = proto3.makeMessageType(
  "com.diagnocat.core.CreateOrganizationResp",
  () => [
    { no: 1, name: "Organization", kind: "message", T: Organization },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetOrganizationNameReq
 */
export const SetOrganizationNameReq = proto3.makeMessageType(
  "com.diagnocat.core.SetOrganizationNameReq",
  () => [
    { no: 1, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetOrganizationNameResp
 */
export const SetOrganizationNameResp = proto3.makeMessageType(
  "com.diagnocat.core.SetOrganizationNameResp",
  () => [
    { no: 1, name: "Organization", kind: "message", T: Organization },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetOrganizationTypeReq
 */
export const SetOrganizationTypeReq = proto3.makeMessageType(
  "com.diagnocat.core.SetOrganizationTypeReq",
  () => [
    { no: 1, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Type", kind: "enum", T: proto3.getEnumType(OrganizationType) },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetOrganizationTypeResp
 */
export const SetOrganizationTypeResp = proto3.makeMessageType(
  "com.diagnocat.core.SetOrganizationTypeResp",
  () => [
    { no: 1, name: "Organization", kind: "message", T: Organization },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetOrganizationContactsReq
 */
export const SetOrganizationContactsReq = proto3.makeMessageType(
  "com.diagnocat.core.SetOrganizationContactsReq",
  () => [
    { no: 1, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Contacts", kind: "message", T: OrganizationContacts },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetOrganizationContactsResp
 */
export const SetOrganizationContactsResp = proto3.makeMessageType(
  "com.diagnocat.core.SetOrganizationContactsResp",
  () => [
    { no: 1, name: "Organization", kind: "message", T: Organization },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetOrganizationSettingsReq
 */
export const SetOrganizationSettingsReq = proto3.makeMessageType(
  "com.diagnocat.core.SetOrganizationSettingsReq",
  () => [
    { no: 1, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Settings", kind: "message", T: OrganizationSettings },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetOrganizationSettingsResp
 */
export const SetOrganizationSettingsResp = proto3.makeMessageType(
  "com.diagnocat.core.SetOrganizationSettingsResp",
  () => [
    { no: 1, name: "Organization", kind: "message", T: Organization },
  ],
);

/**
 * @generated from message com.diagnocat.core.DeleteOrganizationReq
 */
export const DeleteOrganizationReq = proto3.makeMessageType(
  "com.diagnocat.core.DeleteOrganizationReq",
  () => [
    { no: 1, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.DeleteOrganizationResp
 */
export const DeleteOrganizationResp = proto3.makeMessageType(
  "com.diagnocat.core.DeleteOrganizationResp",
  () => [
    { no: 1, name: "DeletedOrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "DeletedInvitationIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.Hack_SetOrganizationUserContactsReq
 */
export const Hack_SetOrganizationUserContactsReq = proto3.makeMessageType(
  "com.diagnocat.core.Hack_SetOrganizationUserContactsReq",
  () => [
    { no: 1, name: "UserID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "Email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.Hack_SetOrganizationUserContactsResp
 */
export const Hack_SetOrganizationUserContactsResp = proto3.makeMessageType(
  "com.diagnocat.core.Hack_SetOrganizationUserContactsResp",
  () => [
    { no: 1, name: "User", kind: "message", T: User },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetOrganizationUserJobPositionsReq
 */
export const SetOrganizationUserJobPositionsReq = proto3.makeMessageType(
  "com.diagnocat.core.SetOrganizationUserJobPositionsReq",
  () => [
    { no: 1, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "UserID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "JobPositions", kind: "enum", T: proto3.getEnumType(OrganizationJobPosition), repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetOrganizationUserJobPositionsResp
 */
export const SetOrganizationUserJobPositionsResp = proto3.makeMessageType(
  "com.diagnocat.core.SetOrganizationUserJobPositionsResp",
  () => [
    { no: 1, name: "Organization", kind: "message", T: Organization },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetOrganizationUserNameReq
 */
export const SetOrganizationUserNameReq = proto3.makeMessageType(
  "com.diagnocat.core.SetOrganizationUserNameReq",
  () => [
    { no: 1, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "UserID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "FirstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "LastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetOrganizationUserNameResp
 */
export const SetOrganizationUserNameResp = proto3.makeMessageType(
  "com.diagnocat.core.SetOrganizationUserNameResp",
  () => [
    { no: 1, name: "Organization", kind: "message", T: Organization },
  ],
);

