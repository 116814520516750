import { FC, ReactNode } from 'react';
import { Layout as LayoutPrimitive } from 'antd';
import cn from 'classnames';

import styles from './layout.module.scss';
import { LayoutContent } from './layout-content';

type InnerLayoutProps = {
  children: ReactNode;
  className?: string;
};

const InnerLayout: FC<InnerLayoutProps> = (props) => {
  const { children, className } = props;

  return <LayoutPrimitive className={cn(styles.layout, className)}>{children}</LayoutPrimitive>;
};

type CompoundedComponent = FC<InnerLayoutProps> & {
  Content: typeof LayoutContent;
};

const Layout = InnerLayout as CompoundedComponent;

Layout.Content = LayoutContent;

export { Layout };
