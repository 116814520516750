// @generated by protoc-gen-connect-web v0.6.0 with parameter "target=ts,ts_nocheck=false"
// @generated from file api/core/svc_adminka.proto (package com.diagnocat.core, syntax proto3)
/* eslint-disable */

import { AdminOrganizationListStreamReq, AdminOrganizationListStreamResp, AdminUserInfoStreamReq, AdminUserInfoStreamResp, AdminUserListStreamReq, AdminUserListStreamResp, SetAdminReq, SetAdminResp, SetOrganizationFeatureFlagReq, SetOrganizationFeatureFlagResp } from "./svc_adminka_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service com.diagnocat.core.AdminService
 */
export const AdminService = {
  typeName: "com.diagnocat.core.AdminService",
  methods: {
    /**
     * Default sort by Created.At
     *
     * @generated from rpc com.diagnocat.core.AdminService.AdminUserListStream
     */
    adminUserListStream: {
      name: "AdminUserListStream",
      I: AdminUserListStreamReq,
      O: AdminUserListStreamResp,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * Default sort by Name
     *
     * @generated from rpc com.diagnocat.core.AdminService.AdminOrganizationListStream
     */
    adminOrganizationListStream: {
      name: "AdminOrganizationListStream",
      I: AdminOrganizationListStreamReq,
      O: AdminOrganizationListStreamResp,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc com.diagnocat.core.AdminService.AdminUserInfoStream
     */
    adminUserInfoStream: {
      name: "AdminUserInfoStream",
      I: AdminUserInfoStreamReq,
      O: AdminUserInfoStreamResp,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc com.diagnocat.core.AdminService.SetAdmin
     */
    setAdmin: {
      name: "SetAdmin",
      I: SetAdminReq,
      O: SetAdminResp,
      kind: MethodKind.Unary,
    },
    /**
     * If OrganizationFeatureFlag.ReadOnly == false
     *
     * @generated from rpc com.diagnocat.core.AdminService.SetOrganizationFeatureFlag
     */
    setOrganizationFeatureFlag: {
      name: "SetOrganizationFeatureFlag",
      I: SetOrganizationFeatureFlagReq,
      O: SetOrganizationFeatureFlagResp,
      kind: MethodKind.Unary,
    },
  }
} as const;

