import { useIntl } from 'react-intl';
import { Card, Checkbox, notification, Space, Typography } from 'antd';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { organizationModel } from '@/entities/organization';
import { userModel } from '@/entities/user';
import {
  FeatureFlag,
  FeatureFlagType,
} from 'protocol/model/dto_organization_pb';
import { featureFlagsDefaultMessages } from '@/shared/config/i18n';

const { Title } = Typography;

export const FeatureFlags = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const loading = useAppSelector(
    organizationModel.selectors.selectOrganizationLoading,
  );
  const organization = useAppSelector(
    organizationModel.selectors.selectOrganization,
  );
  const featureFlags = organization?.FeatureFlags;

  const setFeatureFlag = async (featureFlag: FeatureFlag, value: boolean) => {
    try {
      await dispatch(
        userModel.thunks.setOrganizationFeatureFlag({
          OrganizationID: organization.ID,
          FeatureFlag: featureFlag,
          Value: value,
        }),
      ).unwrap();
    } catch (e) {
      notification.error({
        message: formatMessage({
          id: 'error.cannotSetFeatureFlag',
          defaultMessage: "Can't set feature flag",
        }),
      });
    }
  };

  return (
    <Space direction="vertical" size="large" style={{ display: 'flex' }}>
      <Card
        bodyStyle={{
          display: 'flex',
          gap: '12px',
        }}
        title={
          <Title level={3} style={{ marginBottom: 0 }}>
            {formatMessage({
              id: 'organization.featureFlags',
              defaultMessage: 'Feature flags',
            })}
          </Title>
        }
        loading={loading !== 'succeeded'}
        bordered={false}
      >
        <Space
          direction="vertical"
          size="small"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Title level={4} style={{ marginBottom: 0 }}>
            {formatMessage({
              id: 'organization.featureFlags.group.regulatoryRestrictions',
              defaultMessage: 'Regulatory restrictions',
            })}
          </Title>
          {/* TODO: If need separate by groups and backend will add field FeatureGroupType need to make new separation */}
          {featureFlags
            ?.filter(({ Type }) => Type === FeatureFlagType.FeatureFlagType_Regulatory)
            ?.map(({ Flag, Value, ReadOnly }) => (
              <Checkbox
                key={Flag}
                disabled={ReadOnly}
                checked={Value}
                onChange={() => setFeatureFlag(Flag, !Value)}
              >
                {formatMessage(featureFlagsDefaultMessages[Flag])}
              </Checkbox>
            ))}
        </Space>

        <Space
          direction="vertical"
          size="small"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Title level={4} style={{ marginBottom: 0 }}>
            {formatMessage({
              id: 'organization.featureFlags.group.development',
              defaultMessage: 'Feature flags for development',
            })}
          </Title>

          {featureFlags
            ?.filter(({ Type }) => Type === FeatureFlagType.FeatureFlagType_Development)
            ?.map(({ Flag, Value, ReadOnly }) => (
              <Checkbox
                key={Flag}
                checked={Value}
                disabled={ReadOnly}
                onChange={() => setFeatureFlag(Flag, !Value)}
              >
                {formatMessage(featureFlagsDefaultMessages[Flag])}
              </Checkbox>
            ))}
        </Space>
      </Card>
    </Space>
  );
};
