// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file api/auth/dto_auth.proto (package com.diagnocat.auth, syntax proto3)
/* eslint-disable */

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message com.diagnocat.auth.Identity
 */
export const Identity = proto3.makeMessageType(
  "com.diagnocat.auth.Identity",
  () => [
    { no: 1, name: "Phone", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "Identity" },
    { no: 2, name: "Email", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "Identity" },
  ],
);

/**
 * @generated from message com.diagnocat.auth.AuthState
 */
export const AuthState = proto3.makeMessageType(
  "com.diagnocat.auth.AuthState",
  () => [
    { no: 100, name: "Objective", kind: "enum", T: proto3.getEnumType(AuthState_AuthObjective) },
    { no: 101, name: "Status", kind: "enum", T: proto3.getEnumType(AuthState_AuthStatus) },
    { no: 102, name: "CurrentStep", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 300, name: "CreatedAt", kind: "message", T: Timestamp },
    { no: 301, name: "ExpiresAt", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from enum com.diagnocat.auth.AuthState.AuthObjective
 */
export const AuthState_AuthObjective = proto3.makeEnum(
  "com.diagnocat.auth.AuthState.AuthObjective",
  [
    {no: 0, name: "INVALID_OBJECTIVE"},
    {no: 100, name: "SIGNUP"},
    {no: 101, name: "SIGNUP_VIA_SHARED_PATIENT_INVITATION"},
    {no: 102, name: "SIGNUP_VIA_ORGANIZATION_INVITATION"},
    {no: 103, name: "SIGNUP_VIA_SALESMAN_INVITATION"},
    {no: 104, name: "SIGNIN"},
    {no: 105, name: "RECOVER"},
  ],
);

/**
 * @generated from enum com.diagnocat.auth.AuthState.AuthStatus
 */
export const AuthState_AuthStatus = proto3.makeEnum(
  "com.diagnocat.auth.AuthState.AuthStatus",
  [
    {no: 0, name: "INVALID_STATUS"},
    {no: 100, name: "IN_PROGRESS"},
    {no: 101, name: "SUCCESSFUL"},
    {no: 102, name: "FAILED"},
  ],
);

