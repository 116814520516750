// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".layout-module__layout--qDUMso1s{min-height:100vh !important}.layout-module__content--NvHKa5kt{display:flex !important;padding:0 50px}.layout-module__contentInner--7VL51yUt{display:flex;flex-direction:column;flex-grow:1;padding-top:24px;padding-bottom:24px;margin:0 auto;max-width:var(--max-content-width)}", "",{"version":3,"sources":["webpack://./src/shared/ui/layout/layout.module.scss"],"names":[],"mappings":"AAAA,iCACE,2BAAA,CAGF,kCACE,uBAAA,CACA,cAAA,CAGF,uCACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,gBAAA,CACA,mBAAA,CACA,aAAA,CACA,kCAAA","sourcesContent":[".layout {\n  min-height: 100vh !important;\n}\n\n.content {\n  display: flex !important;\n  padding: 0 50px;\n}\n\n.contentInner {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  padding-top: 24px;\n  padding-bottom: 24px;\n  margin: 0 auto;\n  max-width: var(--max-content-width);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "layout-module__layout--qDUMso1s",
	"content": "layout-module__content--NvHKa5kt",
	"contentInner": "layout-module__contentInner--7VL51yUt"
};
export default ___CSS_LOADER_EXPORT___;
