// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SubscriptionSummary-module__container--49EYV7Ur{display:flex;align-items:center;gap:16px}.SubscriptionSummary-module__title--XALk6yej{font-size:16px}\n\n.SubscriptionSummary-module__date--o3cge356{margin-left:auto}\n\n[dir=\"rtl\"] .SubscriptionSummary-module__date--o3cge356{margin-left:0;margin-right:auto}", "",{"version":3,"sources":["webpack://./src/features/subscriptionSummary/ui/subscriptionSummary/SubscriptionSummary.module.scss"],"names":[],"mappings":"AAAA,iDACE,YAAA,CACA,kBAAA,CACA,QAAA,CAGF,6CACE,cAAA;;AAGF,4CACE,gBAAA;;AADF,wDACE,aAAA,CAAA,iBAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n}\n\n.title {\n  font-size: 16px;\n}\n\n.date {\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SubscriptionSummary-module__container--49EYV7Ur",
	"title": "SubscriptionSummary-module__title--XALk6yej",
	"date": "SubscriptionSummary-module__date--o3cge356"
};
export default ___CSS_LOADER_EXPORT___;
