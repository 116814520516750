import { useIntl } from 'react-intl';
import { Modal, Space, Typography } from 'antd';

import { useAppDispatch } from '@/shared/hooks';

import * as billingModel from '../model';

const { Text } = Typography;

export const useRemovePackageModal = () => {
  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();

  return ({
            packageID,
            accountID,
          }: {
    packageID: string;
    accountID: string;
  }) => {
    Modal.confirm({
      title: formatMessage({
        id: 'removePackageModal.removePackageModalTitle',
        defaultMessage: 'ATTENTION!',
      }),
      cancelText: formatMessage({
        id: 'removePackageModal.removePackageModalCancelText',
        defaultMessage: "No, I didn't mean it!",
      }),
      okText: formatMessage({
        id: 'removePackageModal.removePackageModalOkText',
        defaultMessage: 'Yes, remove package',
      }),
      content: (
        <Space direction="vertical">
          <Text>
            {formatMessage({
              id: 'removePackageModal.removePackageModalContent',
              defaultMessage:
                'You are about to remove user package. It will disrupt user ability to use the application because they will lose their package allowance. Please make sure they are aware of their package removal.',
            })}
          </Text>
          <Text strong>
            {formatMessage({
              id: 'removePackageModal.removePackageModalContent2',
              defaultMessage:
                'Are you sure you want to remove the package?',
            })}
          </Text>
        </Space>
      ),
      okButtonProps: {
        danger: true,
      },
      centered: true,
      width: 600,
      onOk() {
        return dispatch(
          billingModel.thunks.createOrder({
            AccountID: accountID,
            Effect: {
              case: 'ProductStateRemove',
              value: {
                RemoveProductStateID: packageID,
              }
            }
          }),
        );
      },
    });
  };
};
