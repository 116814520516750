import { Empty } from '@bufbuild/protobuf';

import { LogoutReq, RefreshResp, SignInReq } from 'protocol/api/auth/svc_authentication_pb';
import { AuthState } from 'protocol/api/auth/dto_auth_pb';
import { SliceName } from '@/shared/config';
import api, { Authentication } from '@/shared/api/api';
import { createThunkGenerator } from '@/shared/lib';


type AuthRequests = keyof typeof Authentication['methods'];

const generateAuthThunk = createThunkGenerator<AuthRequests>(SliceName.auth);

export const refresh = generateAuthThunk<Empty, RefreshResp>(
  'refresh',
  api.auth.refresh,
);

export const signIn = generateAuthThunk<SignInReq, AuthState>(
  'signIn',
  api.auth.signIn,
);

export const logout = generateAuthThunk<LogoutReq, Empty>(
  'logout',
  api.auth.logout,
);
