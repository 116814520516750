// @generated by protoc-gen-connect-web v0.6.0 with parameter "target=ts,ts_nocheck=false"
// @generated from file api/core/svc_organization.proto (package com.diagnocat.core, syntax proto3)
/* eslint-disable */

import { CreateOrganizationReq, CreateOrganizationResp, DeleteOrganizationReq, DeleteOrganizationResp, Hack_SetOrganizationUserContactsReq, Hack_SetOrganizationUserContactsResp, OrganizationSettingsStreamReq, OrganizationSettingsStreamResp, SetOrganizationContactsReq, SetOrganizationContactsResp, SetOrganizationNameReq, SetOrganizationNameResp, SetOrganizationSettingsReq, SetOrganizationSettingsResp, SetOrganizationTypeReq, SetOrganizationTypeResp, SetOrganizationUserJobPositionsReq, SetOrganizationUserJobPositionsResp, SetOrganizationUserNameReq, SetOrganizationUserNameResp } from "./svc_organization_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * Common errors:
 * - type: RequiredArgumentMissingError, Argument: OrganizationID
 * - type: NotFoundError, Entity: organization (if organization not found by OrganizationID)
 * - type: BadRequestError, Reason: organization deleted (if remote organization found by OrganizationID)
 *
 * @generated from service com.diagnocat.core.OrganizationService
 */
export const OrganizationService = {
  typeName: "com.diagnocat.core.OrganizationService",
  methods: {
    /**
     * Get organization and organization's invitations
     *
     * Permission: `OrganizationPermissions.CanViewStaff`
     *
     * @generated from rpc com.diagnocat.core.OrganizationService.OrganizationSettingsStream
     */
    organizationSettingsStream: {
      name: "OrganizationSettingsStream",
      I: OrganizationSettingsStreamReq,
      O: OrganizationSettingsStreamResp,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * Create new organization or organization branch
     * - Any user can create a new organization
     * - The user who created the new organization is automatically assigned the Owner role
     * - Only Owner or Admin can create branches
     * - If branches are created, then all Owner or Admin user roles are duplicated from the parent organization to the branch
     *
     * Errors:
     * - type: BadRequestError, Reason: parent deleted (if remote organization found by `CreateOrganizationReq.ParentID`)
     * - type: BadRequestError, Reason: solo practitioners organization cannot have branches (if organization with type OrganizationTypeSoloPractitioner found by `CreateOrganizationReq.ParentID`)
     * - type: PermissionDeniedError, Reason: you do not have organization permission `CanEditBranches` (if user create branch and does not have the appropriate permission)
     *
     * @generated from rpc com.diagnocat.core.OrganizationService.CreateOrganization
     */
    createOrganization: {
      name: "CreateOrganization",
      I: CreateOrganizationReq,
      O: CreateOrganizationResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set organization name
     *
     * Permission: `OrganizationPermissions.CanEditInfo`
     *
     * Errors:
     * - type: RequiredArgumentMissingError, Argument: Name
     *
     * @generated from rpc com.diagnocat.core.OrganizationService.SetOrganizationName
     */
    setOrganizationName: {
      name: "SetOrganizationName",
      I: SetOrganizationNameReq,
      O: SetOrganizationNameResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set organization type
     *
     * Permission: `OrganizationPermissions.CanEditInfo`
     *
     * Errors:
     * - type: BadRequestError, Reason: cannot set type SoloPractitioner because organization has branches
     *
     * @generated from rpc com.diagnocat.core.OrganizationService.SetOrganizationType
     */
    setOrganizationType: {
      name: "SetOrganizationType",
      I: SetOrganizationTypeReq,
      O: SetOrganizationTypeResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set organization contacts
     *
     * Permission: `OrganizationPermissions.CanEditInfo`
     *
     * Errors:
     * - type: RequiredArgumentMissingError, Argument: OrganizationID/Contacts - (if the corresponding argument is empty)
     *
     * @generated from rpc com.diagnocat.core.OrganizationService.SetOrganizationContacts
     */
    setOrganizationContacts: {
      name: "SetOrganizationContacts",
      I: SetOrganizationContactsReq,
      O: SetOrganizationContactsResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set organization settings
     *
     * Permission: `OrganizationPermissions.CanEditSettings`
     *
     * Errors:
     * - type: RequiredArgumentMissingError, Argument: OrganizationID/Settings - (if the corresponding argument is empty)
     *
     * @generated from rpc com.diagnocat.core.OrganizationService.SetOrganizationSettings
     */
    setOrganizationSettings: {
      name: "SetOrganizationSettings",
      I: SetOrganizationSettingsReq,
      O: SetOrganizationSettingsResp,
      kind: MethodKind.Unary,
    },
    /**
     * Mark organization as deleted using organization id
     * - this organization mark as deleted
     * - all invitations to this organization are closed
     * - all invitations to the patients/studies/reports of this organization are closed
     *
     * Permission: `OrganizationPermissions.CanEditInfo`
     *
     * Errors:
     * - type: BadRequestError, Reason: organization has alive patients
     * - type: BadRequestError, Reason: organization has employees
     * - type: BadRequestError, Reason: organization has branches
     *
     * @generated from rpc com.diagnocat.core.OrganizationService.DeleteOrganization
     */
    deleteOrganization: {
      name: "DeleteOrganization",
      I: DeleteOrganizationReq,
      O: DeleteOrganizationResp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.diagnocat.core.OrganizationService.Hack_SetUserContacts
     */
    hack_SetUserContacts: {
      name: "Hack_SetUserContacts",
      I: Hack_SetOrganizationUserContactsReq,
      O: Hack_SetOrganizationUserContactsResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set job positions of an organization employee
     *
     * Permission: `OrganizationPermissions.CanEditStaff`
     *
     * @generated from rpc com.diagnocat.core.OrganizationService.SetOrganizationUserJobPositions
     */
    setOrganizationUserJobPositions: {
      name: "SetOrganizationUserJobPositions",
      I: SetOrganizationUserJobPositionsReq,
      O: SetOrganizationUserJobPositionsResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set new user's name to organization role
     *
     * Permission: `OrganizationPermissions.CanEditStaff`
     *
     * @generated from rpc com.diagnocat.core.OrganizationService.SetOrganizationUserName
     */
    setOrganizationUserName: {
      name: "SetOrganizationUserName",
      I: SetOrganizationUserNameReq,
      O: SetOrganizationUserNameResp,
      kind: MethodKind.Unary,
    },
  }
} as const;

