// @generated by protoc-gen-connect-web v0.6.0 with parameter "target=ts,ts_nocheck=false"
// @generated from file api/core/svc_user.proto (package com.diagnocat.core, syntax proto3)
/* eslint-disable */

import { MyDataStreamReq, MyDataStreamResp, SetUserDateOfBirthReq, SetUserDateOfBirthResp, SetUserGenderReq, SetUserGenderResp, SetUserLanguageReq, SetUserLanguageResp, SetUserNameReq, SetUserNameResp } from "./svc_user_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * Common errors:
 * - type: RequiredArgumentMissingError, Argument: UserID
 * - type: NotFoundError, Entity: user (if user not found by UserID)
 *
 * @generated from service com.diagnocat.core.UserService
 */
export const UserService = {
  typeName: "com.diagnocat.core.UserService",
  methods: {
    /**
     * Get stream with information about available organizations
     *
     * @generated from rpc com.diagnocat.core.UserService.MyDataStream
     */
    myDataStream: {
      name: "MyDataStream",
      I: MyDataStreamReq,
      O: MyDataStreamResp,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * Set user name
     *
     * Permission: `UserPermissions.CanEditPersonalData`
     *
     * @generated from rpc com.diagnocat.core.UserService.SetUserName
     */
    setUserName: {
      name: "SetUserName",
      I: SetUserNameReq,
      O: SetUserNameResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set user date of birth
     *
     * Permission: `UserPermissions.CanEditPersonalData`
     *
     * Errors:
     * - type: InvalidArgumentValueError, Argument: DateOfBirth - (if `SetUserDateOfBirthReq.DateOfBirth` is not in the correct format ("2006-01-25"))
     *
     * @generated from rpc com.diagnocat.core.UserService.SetUserDateOfBirth
     */
    setUserDateOfBirth: {
      name: "SetUserDateOfBirth",
      I: SetUserDateOfBirthReq,
      O: SetUserDateOfBirthResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set user gender
     *
     * Permission: `UserPermissions.CanEditInfo`
     *
     * @generated from rpc com.diagnocat.core.UserService.SetUserGender
     */
    setUserGender: {
      name: "SetUserGender",
      I: SetUserGenderReq,
      O: SetUserGenderResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set user Language
     *
     * Permission: `UserPermissions.CanEditInfo`
     *
     * @generated from rpc com.diagnocat.core.UserService.SetUserLanguage
     */
    setUserLanguage: {
      name: "SetUserLanguage",
      I: SetUserLanguageReq,
      O: SetUserLanguageResp,
      kind: MethodKind.Unary,
    },
  }
} as const;

