import api, { Billing } from '@/shared/api/api';
import {
  CreateOrderReq,
  CreateOrderResp,
  GetAvailableProductsReq,
  GetAvailableProductsResp,
  ProcessOrderReq,
  ProcessOrderResp,
  SetAccountBillingInformationReq,
  SetAccountBillingInformationResp,
  SetAccountBillingZonesReq,
  SetAccountBillingZonesResp,
  SetTransactionNumberToOrderReq,
  SetTransactionNumberToOrderResp,
  Test_SetAccountWithoutStripeInvoicesReq,
  Test_SetAccountWithoutStripeInvoicesResp,
} from 'protocol/api/billing_new/svc_billing_new_pb';
import { SliceName } from '@/shared/config';
import { createThunkGenerator } from '@/shared/lib';

type BillingRequests = keyof typeof Billing['methods'];

const generateBillingThunk = createThunkGenerator<BillingRequests>(
  SliceName.billing,
);

export const toggleAccountStripe = generateBillingThunk<
  Test_SetAccountWithoutStripeInvoicesReq,
  Test_SetAccountWithoutStripeInvoicesResp
>(
  'test_SetAccountWithoutStripeInvoices',
  api.billing.test_SetAccountWithoutStripeInvoices,
);

export const setAccountBillingInformation = generateBillingThunk<
  SetAccountBillingInformationReq,
  SetAccountBillingInformationResp
>('setAccountBillingInformation', api.billing.setAccountBillingInformation);

export const processOrder = generateBillingThunk<
  ProcessOrderReq,
  ProcessOrderResp
>('processOrder', api.billing.processOrder);

export const setTransactionNumberToOrder = generateBillingThunk<
  SetTransactionNumberToOrderReq,
  SetTransactionNumberToOrderResp
>('setTransactionNumberToOrder', api.billing.setTransactionNumberToOrder);

export const getAvailableProducts = generateBillingThunk<
  GetAvailableProductsReq,
  GetAvailableProductsResp
>('getAvailableProducts', api.billing.getAvailableProducts);

export const createOrder = generateBillingThunk<
  CreateOrderReq,
  CreateOrderResp
>('createOrder', api.billing.createOrder);

export const setAccountBillingZone = generateBillingThunk<SetAccountBillingZonesReq, SetAccountBillingZonesResp>('setAccountBillingZones', api.billing.setAccountBillingZones);
