import React, { PropsWithChildren } from 'react';

import { ErrorPage } from '@/pages/ErrorPage/ErrorPage';

import { errorTracker } from './errorTracking';
import { ErrorBoundaryProps, State } from './ErrorBoundary.types';

class ErrorBoundary extends React.Component<
  PropsWithChildren<ErrorBoundaryProps>,
  State
> {
  constructor(props: ErrorBoundaryProps | Readonly<ErrorBoundaryProps>) {
    super(props);

    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error | null) {
    errorTracker.notify(error);

    return { error };
  }

  render() {
    const { FallbackComponent, children } = this.props;

    const { error } = this.state;
    const ErrorComponent = FallbackComponent || ErrorPage;

    if (error) {
      return <ErrorComponent error={error} />;
    }

    return children;
  }
}

export default ErrorBoundary;
