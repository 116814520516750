// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BillingInfo-module__space--X-8jsrv6{display:flex;gap:16px}.BillingInfo-module__formItem--LPkg8cGj{width:100%}", "",{"version":3,"sources":["webpack://./src/widgets/billingInfo/ui/BillingInfo/BillingInfo.module.scss"],"names":[],"mappings":"AAAA,qCACE,YAAA,CACA,QAAA,CAGF,wCACE,UAAA","sourcesContent":[".space {\n  display: flex;\n  gap: 16px\n}\n \n.formItem {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"space": "BillingInfo-module__space--X-8jsrv6",
	"formItem": "BillingInfo-module__formItem--LPkg8cGj"
};
export default ___CSS_LOADER_EXPORT___;
