import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  SetAdminReq,
  SetAdminResp,
  SetOrganizationFeatureFlagReq,
  SetOrganizationFeatureFlagResp,
} from 'protocol/api/core/svc_adminka_pb';
import api, {
  AdminService,
  OrganizationService,
  UserService,
} from '@/shared/api/api';
import { createThunkGenerator } from '@/shared/lib';
import { SliceName } from '@/shared/config';
import {
  CreateOrganizationReq,
  CreateOrganizationResp,
} from 'protocol/api/core/svc_organization_pb';
import { SetUserNameReq, SetUserNameResp } from 'protocol/api/core/svc_user_pb';

import { UserLocalSettings } from '../lib/getInitialUserSettings';

type AdminRequest = keyof typeof AdminService['methods'];
type OrganizationRequests = keyof typeof OrganizationService['methods'];
type UserRequests = keyof typeof UserService['methods'];
// TODO: think about 3 services in one slice
// It seems to me that there is no point in spreading it across different slices since these
// are just one queries that contextually relate to the user
const generateAdminThunk = createThunkGenerator<AdminRequest>(SliceName.user);
const generateOrganizationThunk = createThunkGenerator<OrganizationRequests>(
  SliceName.user,
);
const generateUserThunk = createThunkGenerator<UserRequests>(SliceName.user);

export const setSettings = createAsyncThunk(
  'user/setSettings',
  async (settings: Partial<UserLocalSettings>) => {
    Object.entries(settings).forEach(([key, value]) => {
      localStorage.setItem(key, value);
    });

    return settings;
  },
);

export const setAdmin = generateAdminThunk<SetAdminReq, SetAdminResp>(
  'setAdmin',
  api.core.admin.setAdmin,
);

export const setOrganizationFeatureFlag = generateAdminThunk<SetOrganizationFeatureFlagReq, SetOrganizationFeatureFlagResp>(
  'setOrganizationFeatureFlag',
  api.core.admin.setOrganizationFeatureFlag,
);

export const createOrganization = generateOrganizationThunk<
  CreateOrganizationReq,
  CreateOrganizationResp
>('createOrganization', api.organization.createOrganization);

export const setUserName = generateUserThunk<SetUserNameReq, SetUserNameResp>(
  'setUserName',
  api.core.user.setUserName,
);
