// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_user_permissions.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message com.diagnocat.model.UserPermissions
 */
export const UserPermissions = proto3.makeMessageType(
  "com.diagnocat.model.UserPermissions",
  () => [
    { no: 1, name: "CanView", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "CanViewPersonalData", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "CanEditPersonalData", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "CanEditInfo", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "CanViewSettings", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

