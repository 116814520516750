// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountStripeSwitch-module__container--hg9EbJcB{display:inline-flex;gap:8px}", "",{"version":3,"sources":["webpack://./src/features/accountStripeSwitch/ui/AccountStripeSwitch.module.scss"],"names":[],"mappings":"AAAA,iDACE,mBAAA,CACA,OAAA","sourcesContent":[".container {\n  display: inline-flex;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AccountStripeSwitch-module__container--hg9EbJcB"
};
export default ___CSS_LOADER_EXPORT___;
