import { defineMessages } from 'react-intl';
import { OrderStatus } from '../lib';

export const orderStatusText = defineMessages<OrderStatus>({
  'paid': {
    id: 'orderStatus.paid',
    defaultMessage: 'Paid',
  },
  'due': {
    id: 'orderStatus.due',
    defaultMessage: 'Due',
  },
  'overdue': {
    id: 'orderStatus.overdue',
    defaultMessage: 'Overdue',
  },
});
