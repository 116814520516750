import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from '@/app/App';

import 'antd/dist/reset.css';
import './index.css';
import './shared/styles/styles.scss';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
