import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, notification, Radio, Typography, Upload } from "antd";
import { RcFile } from "antd/es/upload";
import { useIntl } from "react-intl";
import { useState } from "react";

import { useAppDispatch } from "@/shared/hooks";
import { ApiError } from "@/shared/api/api";
import { billingModel } from "@/entities/billing";

import styles from './MarkAsPaidModal.module.scss';

const { Text } = Typography;

export const PAYMENT_UPLOAD_MAX_FILE_SIZE = 2 * 1024 * 1024;
export const PAYMENT_UPLOAD_AVAILABLE_TYPES = '.png, .jpg, .jpeg, .pdf';

type MarkAsPaidVariant = 'transactionNumber' | 'uploadReciept';

type MarkAsPaidFormFields = {
  markAsPaidVariant: MarkAsPaidVariant;
  paymentID: string;
  uploadReciept: File;
};

type MarkAsPaidModalProps = {
  orderID: string;
  visible: boolean;
  onCancel: () => void;
};

const uploadPaymentFile = async ({
  receiptImageFile,
  orderID,
}: {
  receiptImageFile: File;
  orderID: string;
}): Promise<void> => {
  const receiptImage: FormData = new FormData();
  receiptImage.append('file', receiptImageFile);

  await fetch(`${process.env.REACT_APP_ROOT_URI}/api/billing/order/${orderID}/document`, {
    method: 'POST',
    body: receiptImage,
    credentials: 'include',
  });
};

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

export const MarkAsPaidModal = (props: MarkAsPaidModalProps) => {
  const { orderID, visible, onCancel } = props;

  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm<MarkAsPaidFormFields>();
  const markAsPaidVariant: MarkAsPaidVariant = Form.useWatch('markAsPaidVariant', form);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const values = await form.validateFields();

      if (values.markAsPaidVariant === 'transactionNumber') {
        await dispatch(billingModel.thunks.setTransactionNumberToOrder({ OrderID: orderID, TransactionNumber: values.paymentID })).unwrap();
      } else {
        await uploadPaymentFile({ receiptImageFile: values.uploadReciept, orderID });
      }

      await dispatch(billingModel.thunks.processOrder({ OrderID: orderID })).unwrap();

      notification.success({ message: formatMessage({ id: 'markAsPaidModal.success', defaultMessage: 'Order successfully marked as paid' }) });
    } catch (error: unknown) {
      const { message } = error as ApiError;

      notification.error({ message: message || formatMessage({ id: 'markAsPaidModal.error', defaultMessage: 'Something went wrong' }) });
    } finally {
      setLoading(false);
      onCancel();
    }
  };

  return (
    <Modal
      title={formatMessage({ id: 'markAsPaidModal.title', defaultMessage: 'Mark as Paid'})}
      open={visible}
      onCancel={onCancel}
      okText={formatMessage({ id: 'markAsPaidModal.okText', defaultMessage: 'Save'})}
      onOk={handleSubmit}
      okButtonProps={{ loading }}
    >
      <Form
        name="migrateAccountForm"
        form={form}
        layout="vertical"
        initialValues={{
          markAsPaidVariant: 'transactionNumber',
        }}
        disabled={loading}
      >
        <Form.Item
          name="markAsPaidVariant"
        >
          <Radio.Group
            size="large"
            className={styles.radioGroup}
          >
            <Radio value="transactionNumber">
              <Text>{formatMessage({ 
                id: 'markAsPaidModal.transactionNumberLabel', 
                defaultMessage: 'Enter transaction number' 
              })}</Text>
            </Radio>

            <Form.Item
              name="paymentID"
              rules={[
                {
                  required: markAsPaidVariant === 'transactionNumber',
                  message: formatMessage({
                    id: "markAsPaidModal.paymentIDPlaceholder",
                    defaultMessage: "Please input the transaction number"
                  }),
                }
              ]}
            >
              <Input
                disabled={markAsPaidVariant === 'uploadReciept'}
              />

            </Form.Item>

            <Radio value="uploadReciept">
              <Text>{formatMessage({ 
                id: 'markAsPaidModal.uploadRecieptLabel', 
                defaultMessage: 'Upload a reciept' 
              })}</Text>
            </Radio>

            <Form.Item
              name="uploadReciept"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              extra={formatMessage({ 
                id: 'markAsPaidModal.uploadExtra', 
                defaultMessage: "png, jpg, jpeg, pdf up to 2mb" 
              })}
              rules={[
                {
                  required: markAsPaidVariant === 'uploadReciept',
                  message: formatMessage({ id: 'markAsPaidModal.uploadReciept.required', defaultMessage: 'Please add a file' }),
                },
                ({ getFieldValue }) => ({
                  validator(_, value: RcFile[]) {
                    if (!value || getFieldValue('markAsPaidVariant') === 'transactionNumber') {
                      return Promise.resolve();
                    }
                    
                    const isLessThen2M = (value[0]?.size || 0) / 1024 / 1024 < 2;

                    if (isLessThen2M) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error(formatMessage({ id: 'markAsPaidModal.uploadReciept.fileSize', defaultMessage: 'File must be smaller than 2MB' })));
                  },
                }),
              ]}
            >
              <Upload
                beforeUpload={() => false}
                maxCount={1}
                disabled={markAsPaidVariant === 'transactionNumber'}
                accept={PAYMENT_UPLOAD_AVAILABLE_TYPES}
                // showUploadList={false}
              >
                <Button
                  disabled={markAsPaidVariant === 'transactionNumber'}
                  icon={<UploadOutlined />}
                >
                  {formatMessage({ id: 'markAsPaidModal.uploadButton', defaultMessage: 'Choose a file from computer' })}
                </Button>
              </Upload>
            </Form.Item>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};
