import cn from 'classnames';

import styles from './BillingHistoryCell.module.scss';

type BillingHistoryCellProps = {
  className?: string;
  text: string;
  label: string;
};

export const BillingHistoryCell = ({ className, text, label }: BillingHistoryCellProps) => (
  <div className={cn(styles.container, className)}>  
    <span className={styles.label}>{label}</span>
    <span className={styles.text}>{text}</span>
  </div>
);
