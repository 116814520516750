import { createEntityAdapter, createSlice, DeepPartial, PayloadAction } from '@reduxjs/toolkit';

import { User } from 'protocol/model/dto_user_pb';
import { SliceName } from '@/shared/config';

export const usersAdapter = createEntityAdapter<DeepPartial<User>>({
  selectId: (user) => user.ID,
});

const initialState = {
  loading: false,
};

const usersSlice = createSlice({
  name: SliceName.users,
  initialState: usersAdapter.getInitialState(initialState),
  reducers: {
    addOne: usersAdapter.addOne,
    addMany: usersAdapter.addMany,
    setOne: usersAdapter.setOne,
    setMany: usersAdapter.setMany,
    removeAll: usersAdapter.removeAll,
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
  },
});

export const { actions } = usersSlice;

export default usersSlice.reducer;
