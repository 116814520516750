import { useState } from 'react';
import { SortOrder } from 'antd/es/table/interface';
import { Code, ConnectError } from '@bufbuild/connect';

import { AdminUserListStreamSortBy } from 'protocol/api/core/svc_adminka_pb';
import api from '@/shared/api/api';
import { useAppDispatch } from '@/shared/hooks';

import * as usersModel from '../model';

type OpenUserListStream = (params: {
  search: string;
  startFromUserID?: string;
  sortBy?: AdminUserListStreamSortBy;
  sortOrder?: SortOrder;
}) => void;

let abortController: AbortController;

export const useUserListStream = () => {
  // use to restore patients state for infinity scroll
  // must be reset after any filters update

  const [resumeToken, setResumeToken] = useState('');

  const dispatch = useAppDispatch();

  const openUserListStream: OpenUserListStream = async ({
    search,
    startFromUserID = '',
    sortBy,
    sortOrder,
  }) => {
    abortController = new AbortController();

    dispatch(usersModel.actions.setLoading(true));

    try {
      const usersListStream = api.core.admin.adminUserListStream(
        {
          StartFromUserID: startFromUserID,
          SearchString: search,
          ResumeToken: resumeToken,
          Limit: 20,
          Sort: [
            {
              By: sortBy,
              Ascending: sortOrder === 'ascend',
            },
          ],
        },
        { signal: abortController.signal },
      );

      for await (const { Update, ResumeToken } of usersListStream) {
        switch (Update.case) {
          case 'InitialUsers': {
            dispatch(usersModel.actions.addMany(Update.value.Users));

            dispatch(usersModel.actions.setLoading(false));
            break;
          }
          case 'UserUpdated': {
            dispatch(usersModel.actions.setOne(Update.value));
            break;
          }

          default: {
            // do nothing
          }
        }

        if (ResumeToken) {
          setResumeToken(ResumeToken);
        }
      }
    } catch (err) {
      if (err instanceof ConnectError && err.code !== Code.Canceled)  {
        dispatch(usersModel.actions.setLoading(false));

        console.error(
          'AdminUserListStream:: error: ',
          err
        );
      }
    }
  };

  const closeUserListStream = (clearStore = true) => {
    abortController.abort();

    if (clearStore) {
      dispatch(usersModel.actions.removeAll());
    }
  };

  return { openUserListStream, closeUserListStream };
};
