import {
  Button,
  Card,
  Form,
  Input,
  notification,
  Select,
  Typography,
} from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from 'react';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { billingModel } from '@/entities/billing';
import { useCountries } from '@/features/countries';

import { BillingInformationFormPayload } from '../../config';

import styles from './BillingInfo.module.scss';

const { Title } = Typography;

const selectAccountInfoLoadingState =
  billingModel.selectors.getLoadingStateSelector('accountInfo');

export const BillingInfo = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const { formatMessage } = useIntl();

  const countriesOptions = useCountries();

  const dispatch = useAppDispatch();

  const { ID, BillingInformation, Country } = useAppSelector(
    billingModel.selectors.selectAccountInfo,
  );
  const accountInfoLoadingState = useAppSelector(selectAccountInfoLoadingState);

  const updateBillingInfo = async (payload: BillingInformationFormPayload) => {
    setLoading(true);

    try {
      await dispatch(
        billingModel.thunks.setAccountBillingInformation({
          AccountID: ID, // TODO: must be replaced to organisation id
          BillingInformation: {
            Phone: payload.phone,
            Address: {
              Region: payload.region,
              City: payload.city,
              ZIP: payload.zipCode,
              Address: payload.companyAddress,
            },
            CompanyName: payload.companyName,
            TaxID: payload.taxID,
          },
        }),
      );
    } catch (error) {
      const parsedMessage = JSON.parse(error?.message);

      notification.error({
        message:
          parsedMessage?.text ??
          parsedMessage?.reason ??
          parsedMessage?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      style={{ height: '100%' }}
      title={
        <Title level={3} style={{ marginBottom: 0 }}>
          <FormattedMessage
            id="billingInfo.title"
            defaultMessage="Billing Information"
          />
        </Title>
      }
      bordered={false}
      loading={accountInfoLoadingState !== 'succeeded'}
    >
      <Form<BillingInformationFormPayload>
        name="billing-info"
        layout="vertical"
        onFinish={updateBillingInfo}
        autoComplete="off"
        disabled={loading}
        initialValues={
          {
            phone: BillingInformation?.Phone,
            companyName: BillingInformation?.CompanyName,
            taxID: BillingInformation?.TaxID,
            country: Country,
            region: BillingInformation?.Address?.Region,
            city: BillingInformation?.Address?.City,
            zipCode: BillingInformation?.Address?.ZIP,
            companyAddress: BillingInformation?.Address?.Address,
          } as BillingInformationFormPayload
        }
      >
        <Form.Item
          className={styles.formItem}
          label={
            <FormattedMessage
              id="billingInfo.companyName"
              defaultMessage="Company name"
            />
          }
          name="companyName"
        >
          <Input
            placeholder={formatMessage({
              id: 'billingInfo.companyNamePlaceholder',
              defaultMessage: 'Enter company name',
            })}
          />
        </Form.Item>

        <div className={styles.space}>
          <Form.Item
            className={styles.formItem}
            label={
              <FormattedMessage
                id="billingInfo.phone"
                defaultMessage="Phone number"
              />
            }
            name="phone"
            rules={[
              {
                required: true,
                message: formatMessage({
                  id: 'forms.required',
                  defaultMessage: 'Field is required',
                }),
              },
            ]}
          >
            <Input
              placeholder={formatMessage({
                id: 'billingInfo.phonePlaceholder',
                defaultMessage: 'Enter phone number',
              })}
            />
          </Form.Item>

          <Form.Item
            className={styles.formItem}
            label={
              <FormattedMessage
                id="billingInfo.taxID"
                defaultMessage="Tax ID"
              />
            }
            name="taxID"
          >
            <Input
              placeholder={formatMessage({
                id: 'billingInfo.companyNamePlaceholder',
                defaultMessage: 'Enter company name',
              })}
            />
          </Form.Item>
        </div>

        <div className={styles.space}>
          <Form.Item
            className={styles.formItem}
            label={
              <FormattedMessage
                id="billingInfo.country"
                defaultMessage="Country"
              />
            }
            name="country"
            rules={[
              {
                required: true,
                message: formatMessage({
                  id: 'forms.required',
                  defaultMessage: 'Field is required',
                }),
              },
            ]}
          >
            <Select disabled={Boolean(Country)} options={countriesOptions} />
          </Form.Item>

          <Form.Item
            className={styles.formItem}
            label={
              <FormattedMessage
                id="billingInfo.region"
                defaultMessage="State/Region"
              />
            }
            name="region"
            rules={[
              {
                required: true,
                message: formatMessage({
                  id: 'forms.required',
                  defaultMessage: 'Field is required',
                }),
              },
            ]}
          >
            <Input
              placeholder={formatMessage({
                id: 'billingInfo.statePlaceholder',
                defaultMessage: 'Enter state/region',
              })}
            />
          </Form.Item>
        </div>

        <div className={styles.space}>
          <Form.Item
            className={styles.formItem}
            label={
              <FormattedMessage id="billingInfo.city" defaultMessage="City" />
            }
            name="city"
            rules={[
              {
                required: true,
                message: formatMessage({
                  id: 'forms.required',
                  defaultMessage: 'Field is required',
                }),
              },
            ]}
          >
            <Input
              placeholder={formatMessage({
                id: 'billingInfo.cityPlaceholder',
                defaultMessage: 'Enter city',
              })}
            />
          </Form.Item>

          <Form.Item
            className={styles.formItem}
            label={
              <FormattedMessage
                id="billingInfo.zipCode"
                defaultMessage="Zip code"
              />
            }
            name="zipCode"
            rules={[
              {
                required: true,
                message: formatMessage({
                  id: 'forms.required',
                  defaultMessage: 'Field is required',
                }),
              },
            ]}
          >
            <Input
              placeholder={formatMessage({
                id: 'billingInfo.zipPlaceholder',
                defaultMessage: 'Enter zip',
              })}
            />
          </Form.Item>
        </div>

        <Form.Item
          className={styles.formItem}
          label={
            <FormattedMessage
              id="billingInfo.companyAddress"
              defaultMessage="Company address"
            />
          }
          name="companyAddress"
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'forms.required',
                defaultMessage: 'Field is required',
              }),
            },
          ]}
        >
          <Input
            placeholder={formatMessage({
              id: 'billingInfo.companyAddressPlaceholder',
              defaultMessage: 'Enter company address',
            })}
          />
        </Form.Item>

        <Button type="primary" htmlType="submit">
          <FormattedMessage id="billingInfo.saveButton" defaultMessage="Save" />
        </Button>
      </Form>
    </Card>
  );
};
