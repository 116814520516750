import { useEffect, useState } from 'react';
import sortBy from 'lodash/sortBy';

import { useAppSelector } from '@/shared/hooks';
import { userModel } from '@/entities/user';

type Countries = Record<string, Record<string, string>>;

const getCountries = async (): Promise<Countries> =>
  import('../config/countries.json').then((countries) => countries.default);

const makeCountryOptions = (countries: Countries, languageKey = 'en-US') =>
  sortBy(
    Object.keys(countries).map((countryKey) => {
      const countryName = countries[countryKey][languageKey];
      return {
        value: countryKey,
        label: countryName,
      };
    }),
    'label',
  );

export const useCountries = () => {
  const [countries, setCountries] = useState([]);

  const { locale } = useAppSelector(userModel.selectors.settings);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const allCountries = await getCountries();

        setCountries(makeCountryOptions(allCountries, locale));
      } catch (error: unknown) {
        console.error(error);
      }
    };

    fetchCountries();
  }, [locale]);

  return countries;
};


