import React, { FC } from 'react';
import { Layout } from 'antd';
import cn from 'classnames';

import styles from './footer.module.scss';

type FooterProps = {
  className?: string;
};

export const Footer: FC<FooterProps> = (props) => {
  const { className } = props;

  return <Layout.Footer className={cn(styles.container, className)}>Diagnocat ©{new Date().getFullYear()}</Layout.Footer>;
};
