import { useState } from 'react';

type UseMarkAsPaidModal = {
  orderID: string;
  visible: boolean;
  openModal: (orderID: string) => void;
  closeModal: () => void;
};

export const useMarkAsPaidModal = (): UseMarkAsPaidModal => {
  const [visible, setVisible] = useState(false);
  const [orderID, setOrderID] = useState(null);

  const openModal = (currentOrderID: string) => {
    setOrderID(currentOrderID);
    setVisible(true);
  };

  const closeModal = () => {
    setOrderID(null);
    setVisible(false);
  };

  return {
    orderID,
    visible,
    openModal,
    closeModal,
  };
};

