import { createConnectTransport } from '@bufbuild/connect-web';
import {
  createPromiseClient,
  Transport,
  PromiseClient,
  ConnectError,
  Interceptor,
} from '@bufbuild/connect';

import { BASE_URI, ENVIRONMENT } from '@/shared/config/config';

import { redirectToSignIn, sleep } from '../lib';

import { Authentication } from './protocol-ts/api/auth/svc_authentication_connectweb';
import { UserService } from './protocol-ts/api/core/svc_user_connectweb';
import { AdminService } from './protocol-ts/api/core/svc_adminka_connectweb';
import { OrganizationService } from './protocol-ts/api/core/svc_organization_connectweb';
import { Billing } from './protocol-ts/api/billing_new/svc_billing_new_connectweb';

const debug = ['staging', 'development'].includes(ENVIRONMENT);
const lockTimeout = 20 * 1e3;

// const logger: Interceptor = (next) => async (req) => {
//   console.log(`sending message to ${req.url}`);
//   return next(req);
// };

class Refresher {
  authAPI: Auth;

  refreshTTL: number;

  accessTTL: number;

  isInited: boolean = false;

  constructor(authAPI: Auth) {
    this.authAPI = authAPI;
    this.refreshTTL = 0;
    this.accessTTL = 0;

    localStorage.setItem('refresh_lock', 'pass');
    // ensures that no dead-lock occurs
    setInterval(()=>{
      const lock = localStorage.getItem('refresh_lock');
      if (lock === 'pass') {
        return;
      }

      if (((new Date()).getTime() - Number(lock)) > lockTimeout) {
        localStorage.setItem('refresh_lock', 'pass');
      }
    }, 100);
  }

  async init() {
    if (this.isInited) {
      return;
    }

    const {AccessTokenTTL, RefreshTokenTTL} = await this.authAPI.getTTLs({});
    this.refreshTTL = Number(RefreshTokenTTL);
    this.accessTTL = Number(AccessTokenTTL); // leave 20% for the refresh
    this.isInited = true;
  }

  async refresh() {
    await this.init();
  
    const ts = Number(localStorage.getItem('refresh_last_at'));
    if ((new Date().getTime()-ts) > (this.accessTTL*0.8)) {
      if (localStorage.getItem('refresh_lock') === 'pass') {
        localStorage.setItem(
          'refresh_lock', (new Date()).getTime().toString());

        try {
          const {AccessTokenTTL, RefreshTokenTTL} = await this.authAPI.refresh({});
          this.refreshTTL = Number(RefreshTokenTTL);
          this.accessTTL = Number(AccessTokenTTL);
        } catch (e) {
          const parsedMessage = JSON.parse((e as APIError)?.rawMessage);

          if (parsedMessage.text === 'refresh token timed out' ||
            parsedMessage.text === 'no session was found for provided access token') {
            localStorage.removeItem('user');
            window.dispatchEvent(new Event('storage'));
            redirectToSignIn();
          }

          throw e;
        } finally {
          localStorage.setItem('refresh_lock', 'pass');
        }

        localStorage.setItem(
          'refresh_last_at', 
          (new Date()).getTime().toString());
      
       } else {
        await sleep(50);
        await this.refresh();
      }
    }
  }
}

const refreshTransport: Transport = createConnectTransport({
  baseUrl: BASE_URI || `https://${window.location.host}/`,
  useBinaryFormat: !(debug || false),
  credentials: 'include', // < Debug
});

type Auth = PromiseClient<typeof Authentication>;
// init auth service for refresh middleware
const auth = createPromiseClient(Authentication, refreshTransport);

const refresher = new Refresher(auth);

interface APIError {
  kind: string;
  rawMessage: string;
}

export const parseError = (err: ConnectError): APIError => {
  const m = JSON.parse(err.rawMessage) as {
    type: string;
  };
  return {
    kind: m.type,
    rawMessage: err.rawMessage,
  };
};

export interface Options {
  baseURL?: string;
  debug?: boolean;
}

const refreshInterceptor: Interceptor = (next) => async (req) => {
  await refresher.refresh();
  const resp = await next(req);
  return resp;
};

const transport: Transport = createConnectTransport({
  baseUrl: BASE_URI || `https://${window.location.host}/`,
  useBinaryFormat: !(debug || false),
  credentials: 'include', // < Debug
  interceptors: [
    refreshInterceptor,
    // logger,
  ],
});

export default {
  core: {
    user: createPromiseClient(UserService, transport),
    admin: createPromiseClient(AdminService, transport),
  },
  billing: createPromiseClient(Billing, transport),
  organization: createPromiseClient(OrganizationService, transport),
  auth: createPromiseClient(Authentication, transport),
  refresher,
};

export { Billing, AdminService, Authentication, OrganizationService, UserService };
