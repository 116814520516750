// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file api/auth/svc_authentication.proto (package com.diagnocat.auth, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { InvitationTarget, InvitationTargetDetails } from "../../model/dto_access_pb.js";

/**
 * @generated from message com.diagnocat.auth.SignInReq
 */
export const SignInReq = proto3.makeMessageType(
  "com.diagnocat.auth.SignInReq",
  () => [
    { no: 1, name: "Email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.auth.SignUp_ByOneself_1_SendUserInfoReq
 */
export const SignUp_ByOneself_1_SendUserInfoReq = proto3.makeMessageType(
  "com.diagnocat.auth.SignUp_ByOneself_1_SendUserInfoReq",
  () => [
    { no: 1, name: "Email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "FirstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "LastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "Language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "Country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "PhoneNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.auth.SignUp_ByOneself_2_ConfirmIdentityReq
 */
export const SignUp_ByOneself_2_ConfirmIdentityReq = proto3.makeMessageType(
  "com.diagnocat.auth.SignUp_ByOneself_2_ConfirmIdentityReq",
  () => [
    { no: 1, name: "ConfirmationCode", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "Action" },
    { no: 2, name: "Resend", kind: "message", T: SignUp_ByOneself_2_ConfirmIdentityReq_ResendConfirmationCode, oneof: "Action" },
  ],
);

/**
 * @generated from message com.diagnocat.auth.SignUp_ByOneself_2_ConfirmIdentityReq.ResendConfirmationCode
 */
export const SignUp_ByOneself_2_ConfirmIdentityReq_ResendConfirmationCode = proto3.makeMessageType(
  "com.diagnocat.auth.SignUp_ByOneself_2_ConfirmIdentityReq.ResendConfirmationCode",
  [],
  {localName: "SignUp_ByOneself_2_ConfirmIdentityReq_ResendConfirmationCode"},
);

/**
 * @generated from message com.diagnocat.auth.SignUp_ByOneself_3_SetPasswordReq
 */
export const SignUp_ByOneself_3_SetPasswordReq = proto3.makeMessageType(
  "com.diagnocat.auth.SignUp_ByOneself_3_SetPasswordReq",
  () => [
    { no: 1, name: "Password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.auth.CheckInviteTokenReq
 */
export const CheckInviteTokenReq = proto3.makeMessageType(
  "com.diagnocat.auth.CheckInviteTokenReq",
  () => [
    { no: 1, name: "Token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.auth.CheckInviteTokenResp
 */
export const CheckInviteTokenResp = proto3.makeMessageType(
  "com.diagnocat.auth.CheckInviteTokenResp",
  () => [
    { no: 1, name: "IsAlreadyRegistered", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 100, name: "InvitationInfo", kind: "message", T: CheckInviteTokenResp_Invitation, oneof: "Info" },
    { no: 101, name: "SignUpViaSalesmanInfo", kind: "message", T: CheckInviteTokenResp_SignUpViaSalesman, oneof: "Info" },
  ],
);

/**
 * @generated from message com.diagnocat.auth.CheckInviteTokenResp.Invitation
 */
export const CheckInviteTokenResp_Invitation = proto3.makeMessageType(
  "com.diagnocat.auth.CheckInviteTokenResp.Invitation",
  () => [
    { no: 1, name: "Target", kind: "message", T: InvitationTarget },
    { no: 2, name: "TargetDetails", kind: "message", T: InvitationTargetDetails },
    { no: 3, name: "TargetOrganizationCountry", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "CheckInviteTokenResp_Invitation"},
);

/**
 * @generated from message com.diagnocat.auth.CheckInviteTokenResp.SignUpViaSalesman
 */
export const CheckInviteTokenResp_SignUpViaSalesman = proto3.makeMessageType(
  "com.diagnocat.auth.CheckInviteTokenResp.SignUpViaSalesman",
  () => [
    { no: 1, name: "FirstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "LastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "Language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "Country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "CheckInviteTokenResp_SignUpViaSalesman"},
);

/**
 * @generated from message com.diagnocat.auth.SignUp_ViaSharedPatientReq
 */
export const SignUp_ViaSharedPatientReq = proto3.makeMessageType(
  "com.diagnocat.auth.SignUp_ViaSharedPatientReq",
  () => [
    { no: 1, name: "InviteToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 100, name: "FirstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 101, name: "LastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 102, name: "Password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 103, name: "Language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 104, name: "Country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.auth.SignUp_ViaOrganizationInviteReq
 */
export const SignUp_ViaOrganizationInviteReq = proto3.makeMessageType(
  "com.diagnocat.auth.SignUp_ViaOrganizationInviteReq",
  () => [
    { no: 1, name: "InviteToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 100, name: "FirstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 101, name: "LastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 102, name: "Password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 103, name: "Language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.auth.SignUp_ViaSalesmanInviteReq
 */
export const SignUp_ViaSalesmanInviteReq = proto3.makeMessageType(
  "com.diagnocat.auth.SignUp_ViaSalesmanInviteReq",
  () => [
    { no: 1, name: "InviteToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 100, name: "Password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.auth.Recover_ByEmail_1_StartReq
 */
export const Recover_ByEmail_1_StartReq = proto3.makeMessageType(
  "com.diagnocat.auth.Recover_ByEmail_1_StartReq",
  () => [
    { no: 1, name: "Email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.auth.Recover_ByEmail_2_SetPasswordReq
 */
export const Recover_ByEmail_2_SetPasswordReq = proto3.makeMessageType(
  "com.diagnocat.auth.Recover_ByEmail_2_SetPasswordReq",
  () => [
    { no: 1, name: "Token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.auth.LogoutReq
 */
export const LogoutReq = proto3.makeMessageType(
  "com.diagnocat.auth.LogoutReq",
  () => [
    { no: 1, name: "Option", kind: "enum", T: proto3.getEnumType(LogoutReq_LogoutOption) },
  ],
);

/**
 * @generated from enum com.diagnocat.auth.LogoutReq.LogoutOption
 */
export const LogoutReq_LogoutOption = proto3.makeEnum(
  "com.diagnocat.auth.LogoutReq.LogoutOption",
  [
    {no: 0, name: "None"},
    {no: 1, name: "SteppedAway"},
    {no: 2, name: "LogoutEverywhere"},
  ],
);

/**
 * @generated from message com.diagnocat.auth.RefreshResp
 */
export const RefreshResp = proto3.makeMessageType(
  "com.diagnocat.auth.RefreshResp",
  () => [
    { no: 1, name: "OK", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "AccessTokenTTL", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "RefreshTokenTTL", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message com.diagnocat.auth.GetTTLsResp
 */
export const GetTTLsResp = proto3.makeMessageType(
  "com.diagnocat.auth.GetTTLsResp",
  () => [
    { no: 1, name: "AccessTokenTTL", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "RefreshTokenTTL", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

