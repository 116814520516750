import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Code, ConnectError } from '@bufbuild/connect';

import api from '@/shared/api/api';
import { useAppDispatch } from '@/shared/hooks';
import { PATHS } from '@/shared/config';
import { userModel } from '@/entities/user';
// TODO: [m|2] Create snippet for useStream
let abortController: AbortController;

export const useUserDataStream = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const openUserDataStream = async () => {
    abortController = new AbortController();
    // First argument its stream parameters second it is for signal and header of request(option object)
    const resp = await api.core.user.myDataStream(
      {},
      { signal: abortController?.signal },
    );

    try {
      for await (const { Update } of resp) {
        switch (Update.case) {
          case 'HistoricalMe': {
            dispatch(userModel.actions.addUser(Update.value));
            break;
          }

          case 'HistoricalOrganizations':
          case 'UpdatedMe':
          case 'UpdatedOrganization':
          case 'Ping':
            break;

          default: {
            //   do nothing
          }
        }
      }
    } catch (error: unknown) {
      if (error instanceof ConnectError && error.code !== Code.Canceled) {
        if (error.code === Code.Unauthenticated) {
          navigate(PATHS.signIn, { state: { from: location } });
        }

        console.error(error);
      }
    }
  };

  useEffect(() => {
    openUserDataStream();

    return () => {
      abortController.abort();

      dispatch(userModel.actions.resetUser());
    };
  }, []);
};
