import { Order } from 'protocol/api/billing_new/dto_order_new_pb';

export const getOrderSubscriptionName = (order: Order): string => {
  const effect = order?.Effect?.Effect;

  switch (effect.case) {
    case 'SubscriptionRenew':
    case 'SubscriptionActivate':
      break;
    case 'SubscriptionReplace': {
      return effect.value.NewSubscription.Name;
    }
    case 'AddonAdd': {
      return effect.value.Addon.Name;
    }
    case 'PackageAdd': {
      return effect.value.Package.Name;
    }
    case 'BonusAdd': {
      return effect.value.Addon.Name;
    }
    case 'SubscriptionAdd': {
      return effect.value.Subscription.Name;
    }

    default:
      return 'Unknown';
  }
};
