import {
  Button,
  Card,
  Checkbox,
  Descriptions,
  Form,
  Input,
  notification,
  Select,
  Typography,
} from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { userModel } from '@/entities/user';
import { AccountStripeSwitch } from '@/features/accountStripeSwitch';
import { useCountries } from '@/features/countries';

import styles from './account.module.scss';

const { Title } = Typography;

type AccountProps = {
  userID: string;
};
type UserForm = {
  firstName: string;
  lastName: string;
  admin: boolean;
};
export const Account = (props: AccountProps) => {
  const { userID } = props;

  const dispatch = useAppDispatch();

  const { formatMessage, formatDate } = useIntl();

  const countriesOptions = useCountries();

  const currentUser = useAppSelector(userModel.selectors.selectCurrentUser);

  const currentUserLoadingState = useAppSelector(
    userModel.selectors.selectCurrentUserLoadingState,
  );

  const [form] = Form.useForm<UserForm>();

  const handleAdminClick = async () => {
    try {
      const { User } = await dispatch(
        userModel.thunks.setAdmin({
          UserID: userID,
          IsAdmin: !currentUser?.IsAdmin,
        }),
      ).unwrap();

      dispatch(userModel.actions.setCurrentUser(User));
    } catch (error) {
      notification.error({ message: error?.message });
    }
  };

  const onFinish = () => {
    form.validateFields().then(async ({ firstName, lastName }) => {
      try {
        await dispatch(
          userModel.thunks.setUserName({
            UserID: currentUser.ID,
            FirstName: firstName,
            LastName: lastName,
          }),
        );
      } catch (error) {
        notification.error({ message: error?.message });
      }
    });
  };

  useEffect(() => {
    form.resetFields();
  }, [currentUser, form]);

  return (
    <Card
      style={{ height: '100%' }}
      title={
        <Title level={3} style={{ marginBottom: 0 }}>
          <FormattedMessage id="account.title" defaultMessage="Account" />
        </Title>
      }
      bordered={false}
      loading={currentUserLoadingState !== 'succeeded'}
    >
      <Form
        name="user"
        onFinish={onFinish}
        form={form}
        layout="horizontal"
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 16 }}
        autoComplete="off"
        initialValues={{
          firstName: currentUser?.PersonalData?.FirstName,
          lastName: currentUser?.PersonalData?.LastName,
          country: currentUser?.Settings?.Country,
          admin: currentUser?.IsAdmin,
        }}
      >
        <Title
          className={styles.subTitle}
          level={5}
          style={{ marginBottom: 16 }}
        >
          <FormattedMessage
            id="account.subtitle.clientInfo"
            defaultMessage="Client info"
          />
        </Title>

        <Descriptions>
          <Descriptions.Item
            style={{ paddingBottom: 8 }}
            label={formatMessage({
              id: 'account.email',
              defaultMessage: 'Email address',
            })}
          >
            {currentUser?.PersonalData?.Emails[0]}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions>
          <Descriptions.Item
            label={formatMessage({
              id: 'global.created',
              defaultMessage: 'Created',
            })}
          >
            {currentUser?.Created?.At?.seconds &&
              formatDate(
                new Date(Number(currentUser.Created.At.seconds) * 1000),
                {
                  year: 'numeric',
                  month: 'long',
                  day: '2-digit',
                },
              )}
          </Descriptions.Item>
        </Descriptions>

        <Form.Item
          className={styles.formItem}
          labelAlign="left"
          label={
            <FormattedMessage
              id="account.firstName"
              defaultMessage="First name"
            />
          }
          name="firstName"
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'forms.required',
                defaultMessage: 'Field is required',
              }),
            },
          ]}
        >
          <Input
            placeholder={formatMessage({
              id: 'account.firstNamePlaceholder',
              defaultMessage: 'Enter first name',
            })}
          />
        </Form.Item>

        <Form.Item
          className={styles.formItem}
          labelAlign="left"
          label={
            <FormattedMessage
              id="account.lastName"
              defaultMessage="Last name"
            />
          }
          name="lastName"
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'forms.required',
                defaultMessage: 'Field is required',
              }),
            },
          ]}
        >
          <Input
            placeholder={formatMessage({
              id: 'account.lastNamePlaceholder',
              defaultMessage: 'Enter last name',
            })}
          />
        </Form.Item>

        <Form.Item
          className={styles.formItem}
          labelAlign="left"
          label={
            <FormattedMessage id="account.country" defaultMessage="Country" />
          }
          name="country"
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'forms.required',
                defaultMessage: 'Field is required',
              }),
            },
          ]}
        >
          <Select
            disabled={Boolean(currentUser?.Settings?.Country)}
            options={countriesOptions}
          />
        </Form.Item>

        <Title level={5}>
          <FormattedMessage
            id="account.subtitle.access"
            defaultMessage="Access"
          />
        </Title>

        <Form.Item className={styles.formItem} name="admin">
          <Checkbox checked={currentUser?.IsAdmin} onChange={handleAdminClick}>
            <FormattedMessage id="account.admin" defaultMessage="Admin" />
          </Checkbox>
        </Form.Item>

        <Button type="primary" htmlType="submit">
          <FormattedMessage id="account.saveButton" defaultMessage="Save" />
        </Button>
      </Form>
    </Card>
  );
};
